﻿//
// Home
//

body.home {

	//
	// Header
	//
	.header {
		margin-bottom: 0;
	}
	article.main-wrap{
		position: relative;
		p{
			margin:0;
		}
		p.main-text{
			img{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto auto;
				width: 400px;
				height: 250px;
				@include breakpoint(medium down) {
					width:50%;
					height:auto;
					right: 10%;
					left: auto;
				}

			}
		}
		&:before{
			content: "";
			display: block;
			position: absolute;
			top:2px;
			left:0;
			height:1px;
			width:100%;
			background-color: $primary-color;
		}
	}
	//観光myタクシーとは？
	article.intro-wrap{
		text-align: center;
		color:$white;
		background:url(#{$imgDir}/common/home-intro-bg.png);
		padding: 4em 0;
		.inner.row{
			h1{
				font-size: rem-calc(31);
			}
			.intro-text{
				font-size: rem-calc(18);
			}
			figure{
				position: relative;
				@include breakpoint(medium down) {
					margin-bottom: 6em;
				}
				img{
					width:200px;
				}
				figcaption{
					margin-top:1em;
				}
				&:not(:last-of-type){
					&:after{
						content: "";
						display: block;
						position: absolute;
						top:30%;
						right: -10px;
						width:0;
						height:0;
						border:1em solid #fff;
						border-top:1em solid transparent;
						border-left:1em solid transparent;
						transform:rotate(-45deg);
						@include breakpoint(small down) {
							bottom: -90%;
							right: 0;
							left: 0;
							margin: auto;
							transform:rotate(45deg);
						}
					}
				}
			}
			.step{
				margin: 3em auto 0;
			}
		}
	}
	//ルートを選ぶ
	article.root{
		p{
			margin:0;
			img{
				width:100%;
			}
		}
		.root-block{
			display: flex;
			align-items:center;
			@include breakpoint(medium down) {
				padding:3em 0;
			}
			@include breakpoint(small down) {
				display: block;
				padding:3em 1em;
			}
			h2{
				margin: 0 auto 1.5em;
			}
			ul.root-list{
			}
			.inner{
				margin:auto;
			}
		}
	}
	//料金について
	article.price{
		p{
			margin:0;
			img{
				width:100%;
			}
		}
		.price-block{
			display: flex;
			align-items:center;
			@include breakpoint(medium down) {
				padding:3em 0;
			}
			@include breakpoint(small down) {
				display: block;
				padding:3em 1em;
			}
			h2{
				margin: 0 auto 1.5em;
			}
			ul.price-detail{
			}
			.inner{
				margin:auto;
			}
		}

	}
	aside.banner-list{
		h2{
			margin:2em 0;
		}
		margin-bottom: 4em;
	}

		// Slick arrows
		.slick-arrow {
			@include image-replacement();
			cursor: pointer;
			outline: 0;
			display: block;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: rgba(#000000, 0.5);
			position: absolute;
			z-index: 2;
			top: 50%;
			margin-top: -30px;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 15px;
				height: 15px;
				border-top: 3px solid #FFF;
				border-left: 3px solid #FFF;
			}

			&.slick-prev {
				left: 10%;
				margin-left: -15px;

				&:after {
					transform: translate(-50%, -50%) rotate(-45deg);
					margin-left: 3px;
				}
			}

			&.slick-next {
				right: 10%;
				margin-right: -15px;

				&:after {
					transform: translate(-50%, -50%) rotate(135deg);
					margin-left: -3px;
				}
			}
		}

		// Slick dots
		.slick-dots {
			display: flex;
			justify-content: center;
			align-items: center;
			list-style: none;
			margin: 20px 0;
			padding: 0;

			> li {
				margin: 0 4px;
				display: flex;
				align-items: center;

				button {
					@include image-replacement();
					border-radius: 50%;
					background-color: #999999;
					width: rem-calc(8);
					height: rem-calc(8);
					cursor: pointer;
				}

				&.slick-active {
					button {
						background-color: $primary-color;
					}
				}
			}
		}


	//
	// Footer
	//
	.footer {

	}
}
