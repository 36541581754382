﻿//
// booking
//

body.booking{
	.page-header{
		background:url(#{$imgDir}/lower/how_to-header-bg.png)no-repeat;
		@include page-header();
	}
	.alert.alert_error.notranslate{
		position: relative;
		&:after{
			content: "";
			width: calc(100% - 7px);
		    height: calc(100% - 7px);
		    border: 1px solid $white;
		    position: absolute;
		    background: transparent;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    margin: auto;
		}
		strong{
			font-size: rem-calc(2);
			color: $white;
			letter-spacing: 0.3em;
		}
	}
	.reserve-wrap{
		margin:4em 0;
		select{
			width:auto;
			margin:0;
		}
		label{
			display: inline-block;
			margin-right: 0.7em;
		}
		table{
			tr{
				th{
					@include breakpoint(large) {
						width:30%;
					}
					border:1px solid $white;
					background-color:$primary-color;
					label{
						color:$white;
						font-weight: bold;
					}
				}
				&:first-of-type{
					th{
						border-top:1px solid $primary-color;
					}
				}
				&:last-of-type{
					th{
						border-bottom:1px solid $primary-color;
					}
				}
				td{
					padding: 1.5em 1em;
					@include breakpoint(small down) {
						text-align: center;
					}
				}
			}
			input{
				margin:0;
			}
			.taxi-number-box{
				margin-top:0.5em;
			}
		}
		.reserve-btn{
			text-align: center;
			font-weight: bold;
			margin: 5em auto;
			display: flex;
			justify-content: center;
			width:340px;
			@include breakpoint(small down) {
				width:270px;
			}
			position: relative;
			transition: 0.2s ease-out;
			&:before{
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: $primary-color;
				z-index: -1;
				transform: skew(-13deg);
				transition: 0.2s ease-out;

			}
			&:after{
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 14px;
				left: 14px;
				background:none;
				border:1px solid $primary-color;
				z-index: -2;
				transform: skew(-13deg);
				transition: 0.2s ease-out;
			}
			&:hover{
				transition: 0.2s ease-out;
				transform:translate(7px,7px);
			}
			&:hover:before{
				background:darken($primary-color,6%);
				transition: 0.2s ease-out;
				transform:translate(0,0) skew(-13deg);
			}
			&:hover:after{
				transition:0.2s ease-out;
				transform:translate(-14px,-14px) skew(-13deg);
			}
			input{
				position: relative;
				margin-bottom: 0;
			    text-decoration: none;
			    width:100%;
			    padding: 0.6em;
			    background: none;
			    border: none;
			    color: white;
			    font-size: rem-calc(25);
			    @include breakpoint(small down) {
			    	font-size: rem-calc(20);
			    }
			    &:hover{
			    	cursor: pointer;
			    }
			}
		}
	}
}