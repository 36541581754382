//
// Typo
//

// text-decoration
// @mixin text-decoration( $decoration: underline, $decoration-hover: none ) {
// 	text-decoration: $decoration;

// 	&:focus,
// 	&:hover {
// 		text-decoration: $decoration-hover;
// 	}
// }

// ellipsis
@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

//
// Utility
//

// full width image
@mixin image-expanded() {
	width: 100%;
	height: auto;
}

// clearfix
@mixin clearfix() {
	&:after {
		content: '';
		clear: both;
		display: block;
	}
}

// image replacement (Scott Kellum Method)
@mixin image-replacement() {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	word-wrap: normal;
}


//page-headerの画像以外のスタイル定義
@mixin page-header() {
	background-size: cover;
	height: 145px;
	color:$white;
	display: flex;
	align-items: center;
	&:before{
		content: "";
		display: block;
		position: absolute;
		top:2px;
		left:0;
		height:1px;
		width:100%;
		background-color: $primary-color;
	}
}