//
// Header
//

// Layout
.header {
	padding: 0 0 0 1em;
	border-bottom: 2px solid $primary-color;
	&-inner {
		position: relative;
		max-width:1090px;
		margin:auto;
		@include breakpoint(small down) {
			height:100%;
			padding:0;
			display: flex;
			justify-content: space-between;
			nav {
				// width: 100%;
			}
		}
		@include breakpoint(medium) {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-wrap: nowrap;

			nav {
				flex: 1 1 auto;
			}
		}
		li{
			&:last-of-type{
				a{
					color:$white;
				}
			}
			a{
			color:$body-font-color;
			text-decoration: none;
			font-weight: bold;
			}
			&:not(:nth-last-of-type(2)){
				span{
					border-right: 1px solid $border-primary;

				}
			}
			&:nth-last-of-type(2){
				@include breakpoint(large) {
					padding-left:1em;
				}
			}
			span{
				padding-right: 2rem;
			}
			&:nth-of-type(3){
				a{
					padding-right:0;
					//-↓自由選択項目を付ける時にコメントアウト
					padding-left: 0;
				}
			}
			&:last-of-type{
				@include breakpoint(large) {
					a{
						padding: 0 1.5rem;
					}
				}
			}

		}
		h1{
			a{
				font-weight: bold;
				@include breakpoint(large) {
					min-height:74px;
				}
				@include breakpoint(medium) {
					width:300px;
				}
				@include breakpoint(small down) {
					width:250px;
				}
				img{
					@include breakpoint(medium down) {
						width:90%;
					}
					@include breakpoint(large) {
						width:100%;
						padding:1em 0;
					}
				}
			}
		}
		.header-button-container{
			button{
				position: relative;
				padding:0.3em 0.7em 0;
				span{
					&:nth-of-type(1){
					    width: 80%;
					    height: 2px;
					    background: #fff;
					    display: block;
					    margin:5px auto;
					}
					&:nth-of-type(2){
						width: 80%;
					    height: 2px;
					    background: #fff;
					    display: block;
					    margin:5px auto;
					}
					&:nth-of-type(3){
						width: 80%;
						height: 2px;
						background: #fff;
						display: block;
						margin:5px auto;
					}
				}
				p{
					color:$white;
					margin:0;
					font-weight: bold;
				}
			}
		}
		nav.sp-menu-content{
			width:230px;
			background-color: $primary-color;
			ul{
				li{
					&:not(:first-of-type){
						border-bottom: 2px dotted $white;
						a{
							color:$white;
							span{
								padding:0;
								border:none;
							}
							&:hover{
								color:$primary-color;
								background-color:$white;
							}
						}
					}
					&:first-of-type{
						background-color: $white;
						border-bottom: 2px dotted $white;
						a{
							padding:1.5em 0.5em;
						}
					}
				}
			}
		}
	}
}

// Logo
.header-logo {
	margin-bottom: 0;
	font-size: 100%;
	display: flex;
	align-items: center;
}

// button (small)
.header-button-container {
	// position: absolute;
	// top: 0;
	// right: 0;

	button {
		background-color: $primary-color;
		cursor: pointer;
	}
}

// Nav
.header-nav {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 100%;
	a{
		cursor: pointer;
	    display: block;
	    text-align: center;
	    position: relative;
	    @include breakpoint(medium down) {
	    	justify-content: center;
	    }

		&:hover .slide-color:before{
			width:100%;
		}
		.slide-color{
		    font-weight: bold;
		    display: inline-block;
		    outline: medium none;
		    text-decoration: none;
		    position: relative;
		    @include breakpoint(large) {
		    	//-自由選択項目を付ける時に復活
		    	// left:1rem;
		    }
			&:before{
				content:"";
				border-bottom: 2px solid;
			    color: $primary-color;
			    @include breakpoint(large) {
			    	content: attr(data-hover);
			    	text-shadow: none;
			    }
			    text-shadow: 0 0 1px #fff;
			    overflow: hidden;
			    white-space: nowrap;
			    padding: 0 0;
			    width: 0;
			    position: absolute;
			    left: -1rem;
			    // right: 0;
			    // bottom: 0;
			    transition: width 0.2s ease 0s;
			    @include breakpoint(medium down) {
			    	left:0;
			    	bottom:0;
			    }
			}
		}
	}
	//SP版言語選択
	// p.header-nav-item{
	// 	position: relative;
	// 	a{
	// 		position: relative;
	// 		background: $white;
	// 		color: $primary-color;
	// 		width:120px;
	// 		height:40px;
	// 		line-height: 30px;
	// 		margin: 4em auto 0;
	// 		text-align: center;
	// 		font-weight: bold;
	// 		padding:  0;
	// 		border-radius: 3px;
	// 		transform: scale(1);
	// 		&:hover{
	// 			background-color:$primary-color;
	// 			color:$white;
	// 			border:2px solid $white;
	// 		}
	// 		&:after{
	// 			content: "";
	// 			position: absolute;
	// 			z-index: -1;
	// 			display: block;
	// 			right:10px;
	// 			bottom:-13px;
	// 			width:40px;
	// 			height:40px;
	// 			background-color: #fff;
	// 			border-radius: 50%;

	// 		}
	// 		&:before{
	// 			content: "";
	// 			position: absolute;
	// 			z-index: -1;
	// 			display: block;
	// 			left:10px;
	// 			bottom:-13px;
	// 			width:40px;
	// 			height:40px;
	// 			background-color: #fff;
	// 			border-radius: 50%;
	// 		}
	// 		span.taxi{
	// 			position: absolute;
	// 			z-index: -1;
	// 			top:-26px;
	// 			left:0;
	// 			right:0;
	// 			margin:auto;
	// 			width:90px;
	// 			border-bottom:40px solid #fff;
	// 			border-left: 20px solid transparent;
	// 			border-right: 20px solid transparent;
	// 		}
	// 		span.language{

	// 		}
	// 		&:hover{
	// 			background-color: $white;
	// 			color:$primary-color;
	// 			border:none;
	// 			transform: scale(1);
	// 		}
	// 	}
	// }

	@include breakpoint(large) {
		display: flex;
		align-items: stretch;
		justify-content: flex-end;
	}

	> li {
		a {
			display: flex;
			align-items: center;
			padding: .5em 1em;
			height: 100%;
			@include breakpoint(medium down) {
				padding:1em 0;
			}
		}
	}
}