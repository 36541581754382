@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}

body {
  word-wrap: break-word;
  color: #333;
}

address {
  font-style: normal;
}

img.expanded {
  width: 100%;
  height: auto;
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=12.5em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 87.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック", "ＭＳ Ｐゴシック", "メイリオ", Meiryo, sans-serif;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 74.28571rem;
  margin-right: auto;
  margin-left: auto;
}

.row::before, .row::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.row::after {
  clear: both;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 12.5em) {
  .row .row {
    margin-right: -0.71429rem;
    margin-left: -0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -1.07143rem;
    margin-left: -1.07143rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -1.07143rem;
    margin-left: -1.07143rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.gutter-small > .column, .row.gutter-small > .columns {
  padding-right: 0.71429rem;
  padding-left: 0.71429rem;
}

.row.gutter-medium > .column, .row.gutter-medium > .columns {
  padding-right: 1.07143rem;
  padding-left: 1.07143rem;
}

.column, .columns {
  width: 100%;
  float: left;
}

@media print, screen and (min-width: 12.5em) {
  .column, .columns {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 1.07143rem;
    padding-left: 1.07143rem;
  }
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 12.5em) {
  .small-1 {
    width: 8.33333%;
  }
  .small-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .small-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .small-offset-0 {
    margin-left: 0%;
  }
  .small-2 {
    width: 16.66667%;
  }
  .small-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .small-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .small-offset-1 {
    margin-left: 8.33333%;
  }
  .small-3 {
    width: 25%;
  }
  .small-push-3 {
    position: relative;
    left: 25%;
  }
  .small-pull-3 {
    position: relative;
    left: -25%;
  }
  .small-offset-2 {
    margin-left: 16.66667%;
  }
  .small-4 {
    width: 33.33333%;
  }
  .small-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .small-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .small-offset-3 {
    margin-left: 25%;
  }
  .small-5 {
    width: 41.66667%;
  }
  .small-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .small-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .small-offset-4 {
    margin-left: 33.33333%;
  }
  .small-6 {
    width: 50%;
  }
  .small-push-6 {
    position: relative;
    left: 50%;
  }
  .small-pull-6 {
    position: relative;
    left: -50%;
  }
  .small-offset-5 {
    margin-left: 41.66667%;
  }
  .small-7 {
    width: 58.33333%;
  }
  .small-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .small-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .small-offset-6 {
    margin-left: 50%;
  }
  .small-8 {
    width: 66.66667%;
  }
  .small-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .small-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .small-offset-7 {
    margin-left: 58.33333%;
  }
  .small-9 {
    width: 75%;
  }
  .small-push-9 {
    position: relative;
    left: 75%;
  }
  .small-pull-9 {
    position: relative;
    left: -75%;
  }
  .small-offset-8 {
    margin-left: 66.66667%;
  }
  .small-10 {
    width: 83.33333%;
  }
  .small-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .small-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .small-offset-9 {
    margin-left: 75%;
  }
  .small-11 {
    width: 91.66667%;
  }
  .small-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .small-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .small-offset-10 {
    margin-left: 83.33333%;
  }
  .small-12 {
    width: 100%;
  }
  .small-offset-11 {
    margin-left: 91.66667%;
  }
  .small-up-1 > .column, .small-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left;
  }
  .small-up-2 > .column, .small-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left;
  }
  .small-up-3 > .column, .small-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left;
  }
  .small-up-4 > .column, .small-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left;
  }
  .small-up-5 > .column, .small-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left;
  }
  .small-up-6 > .column, .small-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left;
  }
  .small-up-7 > .column, .small-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left;
  }
  .small-up-8 > .column, .small-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left;
  }
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .small-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .small-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem;
  }
  .small-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .small-uncentered,
  .small-push-0,
  .small-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%;
  }
  .medium-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    width: 16.66667%;
  }
  .medium-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-push-3 {
    position: relative;
    left: 25%;
  }
  .medium-pull-3 {
    position: relative;
    left: -25%;
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .medium-4 {
    width: 33.33333%;
  }
  .medium-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    width: 41.66667%;
  }
  .medium-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .medium-6 {
    width: 50%;
  }
  .medium-push-6 {
    position: relative;
    left: 50%;
  }
  .medium-pull-6 {
    position: relative;
    left: -50%;
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .medium-7 {
    width: 58.33333%;
  }
  .medium-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    width: 66.66667%;
  }
  .medium-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-push-9 {
    position: relative;
    left: 75%;
  }
  .medium-pull-9 {
    position: relative;
    left: -75%;
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .medium-10 {
    width: 83.33333%;
  }
  .medium-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    width: 91.66667%;
  }
  .medium-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.07143rem;
    padding-left: 1.07143rem;
  }
  .medium-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%;
  }
  .large-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .large-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    width: 16.66667%;
  }
  .large-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .large-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .large-3 {
    width: 25%;
  }
  .large-push-3 {
    position: relative;
    left: 25%;
  }
  .large-pull-3 {
    position: relative;
    left: -25%;
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .large-4 {
    width: 33.33333%;
  }
  .large-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .large-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    width: 41.66667%;
  }
  .large-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .large-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .large-6 {
    width: 50%;
  }
  .large-push-6 {
    position: relative;
    left: 50%;
  }
  .large-pull-6 {
    position: relative;
    left: -50%;
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .large-7 {
    width: 58.33333%;
  }
  .large-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .large-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    width: 66.66667%;
  }
  .large-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .large-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .large-9 {
    width: 75%;
  }
  .large-push-9 {
    position: relative;
    left: 75%;
  }
  .large-pull-9 {
    position: relative;
    left: -75%;
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .large-10 {
    width: 83.33333%;
  }
  .large-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .large-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    width: 91.66667%;
  }
  .large-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .large-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .large-12 {
    width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.07143rem;
    padding-left: 1.07143rem;
  }
  .large-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 12.5em) {
  .column-block {
    margin-bottom: 1.42857rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 2.14286rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.8;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック", "ＭＳ Ｐゴシック", "メイリオ", Meiryo, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca;
}

@media print, screen and (min-width: 12.5em) {
  h1, .h1 {
    font-size: 2.14286rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h2, .h2 {
    font-size: 1.71429rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h3, .h3 {
    font-size: 1.71429rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h4, .h4 {
    font-size: 1.42857rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h5, .h5 {
    font-size: 1.21429rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h6, .h6 {
    font-size: 1.14286rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3.28571rem;
  }
  h2, .h2 {
    font-size: 2.21429rem;
  }
  h3, .h3 {
    font-size: 2.21429rem;
  }
  h4, .h4 {
    font-size: 1.78571rem;
  }
  h5, .h5 {
    font-size: 1.5rem;
  }
  h6, .h6 {
    font-size: 1.28571rem;
  }
}

a {
  line-height: inherit;
  color: #0078D7;
  text-decoration: underline;
  cursor: pointer;
}

a:hover, a:focus {
  color: #0067b9;
  text-decoration: none;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 74.28571rem;
  height: 0;
  margin: 1.42857rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.8;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.64286rem 1.42857rem 0 1.35714rem;
  border-left: 1px solid #cacaca;
}

blockquote, blockquote p {
  line-height: 1.8;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.92857rem;
  color: #8a8a8a;
}

cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.14286rem 0.35714rem 0.07143rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #333;
}

kbd {
  margin: 0;
  padding: 0.14286rem 0.28571rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #333;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.8;
  color: #8a8a8a;
}

.lead {
  font-size: 109.375%;
  line-height: 1.8;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

@media print, screen and (min-width: 12.5em) {
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 3.12857rem;
  margin: 0 0 1.14286rem;
  padding: 0.57143rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(51, 51, 51, 0.1);
  font-family: inherit;
  font-size: 1.14286rem;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fff;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0;
}

input[type='search'] {
  box-sizing: border-box;
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1.14286rem;
}

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.57143rem;
  margin-right: 1.14286rem;
  margin-bottom: 0;
}

[type='checkbox'] + label[for],
[type='radio'] + label[for] {
  cursor: pointer;
}

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.57143rem;
}

[type='file'] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
}

label.middle {
  margin: 0 0 1.14286rem;
  padding: 0.64286rem 0;
}

.help-text {
  margin-top: -0.57143rem;
  font-size: 0.92857rem;
  font-style: italic;
  color: #333;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1.14286rem;
  align-items: stretch;
}

.input-group > :first-child {
  border-radius: 0 0 0 0;
}

.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #333;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.14286rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.57143rem;
}

.fieldset {
  margin: 1.28571rem 0;
  padding: 1.42857rem;
  border: 1px solid #cacaca;
}

.fieldset legend {
  margin: 0;
  margin-left: -0.21429rem;
  padding: 0 0.21429rem;
}

select {
  height: 3.12857rem;
  margin: 0 0 1.14286rem;
  padding: 0.57143rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  font-size: 1.14286rem;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1.14286rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.71429rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}

select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fff;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb;
}

.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.57143rem;
  margin-bottom: 1.14286rem;
  font-size: 0.85714rem;
  font-weight: bold;
  color: #cc4b37;
}

.form-error.is-visible {
  display: block;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0078D7;
  color: #fff;
}

[data-whatinput='mouse'] .button {
  outline: 0;
}

.button:hover, .button:focus {
  background-color: #0066b7;
  color: #fff;
}

.button.tiny {
  font-size: 0.6rem;
}

.button.small {
  font-size: 0.75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #0078D7;
  color: #fff;
}

.button.primary:hover, .button.primary:focus {
  background-color: #0060ac;
  color: #fff;
}

.button.secondary {
  background-color: #414141;
  color: #fff;
}

.button.secondary:hover, .button.secondary:focus {
  background-color: #343434;
  color: #fff;
}

.button.success {
  background-color: #3adb76;
  color: #333;
}

.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #333;
}

.button.warning {
  background-color: #ffae00;
  color: #333;
}

.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #333;
}

.button.alert {
  background-color: #cc4b37;
  color: #fff;
}

.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #fff;
}

.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #0078D7;
  color: #fff;
}

.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #0078D7;
  color: #fff;
}

.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #414141;
  color: #fff;
}

.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #333;
}

.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #333;
}

.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fff;
}

.button.hollow {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}

.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent;
}

.button.hollow:hover, .button.hollow:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow.primary {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow.secondary {
  border: 1px solid #414141;
  color: #414141;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #212121;
  color: #212121;
}

.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #414141;
  color: #414141;
}

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent;
}

.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent;
}

.button.clear:hover, .button.clear:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent;
}

.button.clear.primary {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent;
}

.button.clear.secondary {
  border: 1px solid #414141;
  color: #414141;
}

.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #212121;
  color: #212121;
}

.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #414141;
  color: #414141;
}

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent;
}

.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent;
}

.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent;
}

.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent;
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fff transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.dropdown.hollow::after {
  border-top-color: #0078D7;
}

.button.dropdown.hollow.primary::after {
  border-top-color: #0078D7;
}

.button.dropdown.hollow.secondary::after {
  border-top-color: #414141;
}

.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}

.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

.accordion {
  margin-left: 0;
  background: #fff;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.85714rem;
  line-height: 1;
  color: #0078D7;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+';
}

.is-active > .accordion-title::before {
  content: '\2013';
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fff;
  color: #333;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.accordion-menu li {
  width: 100%;
}

.accordion-menu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem;
}

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0078D7 transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem;
}

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem;
}

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem;
}

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.is-accordion-submenu-parent {
  position: relative;
}

.has-submenu-toggle > a {
  margin-right: 40px;
}

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0078D7 transparent transparent;
  top: 0;
  bottom: 0;
  margin: auto;
}

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%;
}

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #0078D7;
  color: #fff;
}

.badge.primary {
  background: #0078D7;
  color: #fff;
}

.badge.secondary {
  background: #414141;
  color: #fff;
}

.badge.success {
  background: #3adb76;
  color: #333;
}

.badge.warning {
  background: #ffae00;
  color: #333;
}

.badge.alert {
  background: #cc4b37;
  color: #fff;
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}

.breadcrumbs::before, .breadcrumbs::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.breadcrumbs::after {
  clear: both;
}

.breadcrumbs li {
  float: left;
  font-size: 0.78571rem;
  color: #333;
  cursor: default;
}

.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: #cacaca;
}

.breadcrumbs a {
  color: #0078D7;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

.button-group::before, .button-group::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.button-group::after {
  clear: both;
}

.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem;
  flex: 0 0 auto;
}

.button-group .button:last-child {
  margin-right: 0;
}

.button-group.tiny .button {
  font-size: 0.6rem;
}

.button-group.small .button {
  font-size: 0.75rem;
}

.button-group.large .button {
  font-size: 1.25rem;
}

.button-group.expanded .button {
  flex: 1 1 0px;
}

.button-group.primary .button {
  background-color: #0078D7;
  color: #fff;
}

.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #0060ac;
  color: #fff;
}

.button-group.secondary .button {
  background-color: #414141;
  color: #fff;
}

.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #343434;
  color: #fff;
}

.button-group.success .button {
  background-color: #3adb76;
  color: #333;
}

.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #333;
}

.button-group.warning .button {
  background-color: #ffae00;
  color: #333;
}

.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #333;
}

.button-group.alert .button {
  background-color: #cc4b37;
  color: #fff;
}

.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #fff;
}

.button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
  flex-wrap: wrap;
}

.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}

.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 0;
  background-color: white;
  color: #333;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #d3ebff;
  color: #333;
}

.callout.secondary {
  background-color: #e3e3e3;
  color: #333;
}

.callout.success {
  background-color: #e1faea;
  color: #333;
}

.callout.warning {
  background-color: #fff3d9;
  color: #333;
}

.callout.alert {
  background-color: #f7e4e1;
  color: #333;
}

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  overflow: hidden;
  color: #333;
}

.card > :last-child {
  margin-bottom: 0;
}

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6;
}

.card-divider > :last-child {
  margin-bottom: 0;
}

.card-section {
  flex: 1 0 auto;
  padding: 1rem;
}

.card-section > :last-child {
  margin-bottom: 0;
}

.card-image {
  min-height: 1px;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}

[data-whatinput='mouse'] .close-button {
  outline: 0;
}

.close-button:hover, .close-button:focus {
  color: #333;
}

.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}

.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

[data-whatinput='mouse'] .menu li {
  outline: 0;
}

.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}

.menu input {
  display: inline-block;
}

.menu, .menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}

.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}

.menu.expanded li {
  flex: 1 1 0px;
}

.menu.simple {
  align-items: center;
}

.menu.simple li + li {
  margin-left: 1rem;
}

.menu.simple a {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}

.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}

.menu.icons a {
  display: flex;
}

.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: flex;
}

.menu.icon-left li a {
  flex-flow: row nowrap;
}

.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}

.menu.icon-right li a {
  flex-flow: row nowrap;
}

.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}

.menu.icon-top li a {
  flex-flow: column nowrap;
}

.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu.icon-bottom li a {
  flex-flow: column nowrap;
}

.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu .is-active > a {
  background: #0078D7;
  color: #fff;
}

.menu .active > a {
  background: #0078D7;
  color: #fff;
}

.menu.align-left {
  justify-content: flex-start;
}

.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}

.menu.align-right li .submenu li {
  justify-content: flex-start;
}

.menu.align-right.vertical li {
  display: block;
  text-align: right;
}

.menu.align-right.vertical li .submenu li {
  text-align: right;
}

.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}

.menu.align-center li {
  display: flex;
  justify-content: center;
}

.menu.align-center li .submenu li {
  justify-content: flex-start;
}

.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered > .menu {
  justify-content: center;
}

.menu-centered > .menu li {
  display: flex;
  justify-content: center;
}

.menu-centered > .menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
  content: '';
}

.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #333;
  box-shadow: 0 7px 0 #333, 0 14px 0 #333;
  content: '';
}

.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}

.is-drilldown {
  position: relative;
  overflow: hidden;
}

.is-drilldown li {
  display: block;
}

.is-drilldown.animate-height {
  transition: height 0.5s;
}

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fff;
}

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fff;
  transition: transform 0.15s linear;
}

.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  transform: translateX(-100%);
}

.drilldown .is-drilldown-submenu.is-closing {
  transform: translateX(100%);
}

.drilldown .is-drilldown-submenu a {
  padding: 0.7rem 1rem;
}

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%;
}

.drilldown .is-drilldown-submenu-parent > a {
  position: relative;
}

.drilldown .is-drilldown-submenu-parent > a::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
}

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem;
}

.dropdown-pane.is-opening {
  display: block;
}

.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0078D7 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px;
}

.dropdown.menu a {
  padding: 0.7rem 1rem;
}

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0;
}

.dropdown.menu .is-active > a {
  background: transparent;
  color: #0078D7;
}

.no-js .dropdown.menu ul {
  display: none;
}

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.dropdown.menu.vertical > li > a::after {
  right: 14px;
}

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
}

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0078D7 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0078D7 transparent transparent;
  }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0078D7;
  }
}

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0078D7 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0078D7 transparent transparent;
  }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0078D7;
  }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fff;
}

.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem;
}

.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}

.is-dropdown-submenu > li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1.14286rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #0078D7;
  color: #fff;
}

.label.primary {
  background: #0078D7;
  color: #fff;
}

.label.secondary {
  background: #414141;
  color: #fff;
}

.label.success {
  background: #3adb76;
  color: #333;
}

.label.warning {
  background: #ffae00;
  color: #333;
}

.label.alert {
  background: #cc4b37;
  color: #fff;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}

.media-object img {
  max-width: none;
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}

.media-object-section:first-child {
  padding-right: 1rem;
}

.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}

.media-object-section > :last-child {
  margin-bottom: 0;
}

.media-object-section.main-section {
  flex: 1 1 0px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}

.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}

.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}

.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput='mouse'] .off-canvas {
  outline: 0;
}

.off-canvas.is-transition-push {
  z-index: 12;
}

.off-canvas.is-closed {
  visibility: hidden;
}

.off-canvas.is-transition-overlap {
  z-index: 13;
}

.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.7);
}

.off-canvas.is-open {
  transform: translate(0, 0);
}

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput='mouse'] .off-canvas-absolute {
  outline: 0;
}

.off-canvas-absolute.is-transition-push {
  z-index: 12;
}

.off-canvas-absolute.is-closed {
  visibility: hidden;
}

.off-canvas-absolute.is-transition-overlap {
  z-index: 13;
}

.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.7);
}

.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-left {
    width: 250px;
    transform: translateX(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-left {
    width: 1024px;
    transform: translateX(-1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-1024px);
  }
}

.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(1024px);
  }
}

.position-left.is-transition-push {
  box-shadow: inset -13px 0 20px -13px rgba(51, 51, 51, 0.25);
}

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-right {
    width: 250px;
    transform: translateX(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-right {
    width: 1024px;
    transform: translateX(1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(1024px);
  }
}

.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-1024px);
  }
}

.position-right.is-transition-push {
  box-shadow: inset 13px 0 20px -13px rgba(51, 51, 51, 0.25);
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-top {
    height: 250px;
    transform: translateY(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-top {
    height: 1024px;
    transform: translateY(-1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-1024px);
  }
}

.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(1024px);
  }
}

.position-top.is-transition-push {
  box-shadow: inset 0 -13px 20px -13px rgba(51, 51, 51, 0.25);
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-bottom {
    height: 250px;
    transform: translateY(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-bottom {
    height: 1024px;
    transform: translateY(1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(1024px);
  }
}

.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-1024px);
  }
}

.position-bottom.is-transition-push {
  box-shadow: inset 0 13px 20px -13px rgba(51, 51, 51, 0.25);
}

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.off-canvas-content.has-transition-push {
  transform: translate(0, 0);
}

.off-canvas-content .off-canvas.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 1024px;
  }
  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 1024px;
  }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 1024px;
  }
  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 1024px;
  }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 1024px;
  }
  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 1024px;
  }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 1024px;
  }
  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 1024px;
  }
}

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 1024px;
  }
  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 1024px;
  }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 1024px;
  }
  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 1024px;
  }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 1024px;
  }
  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 1024px;
  }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 1024px;
  }
  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 1024px;
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-medium .close-button {
    display: none;
  }
}

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-large .close-button {
    display: none;
  }
}

.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  color: #fff;
}

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fff;
}

[data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
  outline: 0;
}

.orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
  background-color: rgba(51, 51, 51, 0.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

[data-whatinput='mouse'] .orbit-bullets {
  outline: 0;
}

.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #cacaca;
}

.orbit-bullets button:hover {
  background-color: #8a8a8a;
}

.orbit-bullets button.is-active {
  background-color: #8a8a8a;
}

.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}

.pagination::before, .pagination::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.pagination::after {
  clear: both;
}

.pagination li {
  margin-right: 0.07143rem;
  border-radius: 0;
  font-size: 1rem;
  display: none;
}

.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}

@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}

.pagination a,
.pagination button {
  display: block;
  padding: 0.21429rem 0.71429rem;
  border-radius: 0;
  color: #333;
}

.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}

.pagination .current {
  padding: 0.21429rem 0.71429rem;
  background: #0078D7;
  color: #fff;
  cursor: default;
}

.pagination .disabled {
  padding: 0.21429rem 0.71429rem;
  color: #cacaca;
  cursor: not-allowed;
}

.pagination .disabled:hover {
  background: transparent;
}

.pagination .ellipsis::after {
  padding: 0.21429rem 0.71429rem;
  content: '\2026';
  color: #333;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab';
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb';
}

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca;
}

.progress.primary .progress-meter {
  background-color: #0078D7;
}

.progress.secondary .progress-meter {
  background-color: #414141;
}

.progress.success .progress-meter {
  background-color: #3adb76;
}

.progress.warning .progress-meter {
  background-color: #ffae00;
}

.progress.alert .progress-meter {
  background-color: #cc4b37;
}

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #0078D7;
}

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
}

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out;
}

.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #0078D7;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}

[data-whatinput='mouse'] .slider-handle {
  outline: 0;
}

.slider-handle:hover {
  background-color: #0066b7;
}

.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}

.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}

.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

.sticky-container {
  position: relative;
}

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.sticky.is-stuck.is-at-top {
  top: 0;
}

.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}

.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(51, 51, 51, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .columns {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 74.28571rem;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 74.28571rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 74.28571rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 74.28571rem;
  }
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}

input + .switch-paddle {
  margin: 0;
}

.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #fff;
  transition: all 0.25s ease-out;
  content: '';
}

input:checked ~ .switch-paddle {
  background: #0078D7;
}

input:checked ~ .switch-paddle::after {
  left: 2.25rem;
}

[data-whatinput='mouse'] input:focus ~ .switch-paddle {
  outline: 0;
}

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}

input:checked + label > .switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}

input:checked + label > .switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}

.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.71429rem;
}

.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}

.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.85714rem;
}

.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}

.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}

.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1.14286rem;
}

.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}

.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}

table thead,
table tbody,
table tfoot {
  border: 1px solid #f2f2f2;
  background-color: #fff;
}

table caption {
  padding: 0.57143rem 0.71429rem 0.71429rem;
  font-weight: bold;
}

table thead {
  background: #e8e8e8;
  color: #333;
}

table tfoot {
  background: #f2f2f2;
  color: #333;
}

table thead tr,
table tfoot tr {
  background: transparent;
}

table thead th,
table thead td,
table tfoot th,
table tfoot td {
  padding: 0.57143rem 0.71429rem 0.71429rem;
  font-weight: bold;
  text-align: left;
}

table tbody th,
table tbody td {
  padding: 0.57143rem 0.71429rem 0.71429rem;
}

table tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f2f2f2;
}

table.unstriped tbody {
  background-color: #fff;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
}

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #e3e3e3;
}

table.hover tfoot tr:hover {
  background-color: #ededed;
}

table.hover tbody tr:hover {
  background-color: #fafafa;
}

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed;
}

.table-scroll {
  overflow-x: auto;
}

.table-scroll table {
  width: auto;
}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fff;
  list-style-type: none;
}

.tabs::before, .tabs::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}

.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #0078D7;
}

.tabs.primary > li > a {
  color: #fff;
}

.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #0072cc;
}

.tabs-title {
  float: left;
}

.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 0.85714rem;
  line-height: 1;
  color: #0078D7;
}

.tabs-title > a:hover {
  background: #fff;
  color: #0067b9;
}

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #e6e6e6;
  color: #0078D7;
}

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fff;
  color: #333;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}

.tabs-panel.is-active {
  display: block;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(51, 51, 51, 0.2);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}

a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(0, 120, 215, 0.5);
}

a.thumbnail image {
  box-shadow: none;
}

.title-bar {
  padding: 0.5rem;
  background: #333;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #333;
  font-size: 80%;
  color: #fff;
}

.tooltip::before {
  position: absolute;
}

.tooltip.bottom::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #333;
  bottom: 100%;
}

.tooltip.bottom.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #333 transparent transparent;
  top: 100%;
  bottom: auto;
}

.tooltip.top.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #333;
  left: 100%;
}

.tooltip.left.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #333 transparent transparent;
  right: 100%;
  left: auto;
}

.tooltip.right.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.align-top::before {
  bottom: auto;
  top: 10%;
}

.tooltip.align-bottom::before {
  bottom: 10%;
  top: auto;
}

.tooltip.align-left::before {
  left: 10%;
  right: auto;
}

.tooltip.align-right::before {
  left: auto;
  right: 10%;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}

.top-bar,
.top-bar ul {
  background-color: #e6e6e6;
}

.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}

.top-bar input.button {
  width: auto;
}

.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
    margin-right: auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 12.4375em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=12.5em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 87.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック", "ＭＳ Ｐゴシック", "メイリオ", Meiryo, sans-serif;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.grid-container {
  max-width: 74.28571rem;
  margin: 0 auto;
}

@media print, screen and (min-width: 12.5em) {
  .grid-container {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-container {
    padding-right: 1.07143rem;
    padding-left: 1.07143rem;
  }
}

.grid-container.fluid {
  max-width: 100%;
  margin: 0 auto;
}

@media print, screen and (min-width: 12.5em) {
  .grid-container.fluid {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-container.fluid {
    padding-right: 1.07143rem;
    padding-left: 1.07143rem;
  }
}

.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin: 0 auto;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}

.cell.auto {
  flex: 1 1 0px;
}

.cell.shrink {
  flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}

.grid-x > .shrink {
  width: auto;
}

@media print, screen and (min-width: 12.5em) {
  .grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-x > .small-1 {
    width: 8.33333%;
  }
  .grid-x > .small-2 {
    width: 16.66667%;
  }
  .grid-x > .small-3 {
    width: 25%;
  }
  .grid-x > .small-4 {
    width: 33.33333%;
  }
  .grid-x > .small-5 {
    width: 41.66667%;
  }
  .grid-x > .small-6 {
    width: 50%;
  }
  .grid-x > .small-7 {
    width: 58.33333%;
  }
  .grid-x > .small-8 {
    width: 66.66667%;
  }
  .grid-x > .small-9 {
    width: 75%;
  }
  .grid-x > .small-10 {
    width: 83.33333%;
  }
  .grid-x > .small-11 {
    width: 91.66667%;
  }
  .grid-x > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .medium-1 {
    width: 8.33333%;
  }
  .grid-x > .medium-2 {
    width: 16.66667%;
  }
  .grid-x > .medium-3 {
    width: 25%;
  }
  .grid-x > .medium-4 {
    width: 33.33333%;
  }
  .grid-x > .medium-5 {
    width: 41.66667%;
  }
  .grid-x > .medium-6 {
    width: 50%;
  }
  .grid-x > .medium-7 {
    width: 58.33333%;
  }
  .grid-x > .medium-8 {
    width: 66.66667%;
  }
  .grid-x > .medium-9 {
    width: 75%;
  }
  .grid-x > .medium-10 {
    width: 83.33333%;
  }
  .grid-x > .medium-11 {
    width: 91.66667%;
  }
  .grid-x > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .large-1 {
    width: 8.33333%;
  }
  .grid-x > .large-2 {
    width: 16.66667%;
  }
  .grid-x > .large-3 {
    width: 25%;
  }
  .grid-x > .large-4 {
    width: 33.33333%;
  }
  .grid-x > .large-5 {
    width: 41.66667%;
  }
  .grid-x > .large-6 {
    width: 50%;
  }
  .grid-x > .large-7 {
    width: 58.33333%;
  }
  .grid-x > .large-8 {
    width: 66.66667%;
  }
  .grid-x > .large-9 {
    width: 75%;
  }
  .grid-x > .large-10 {
    width: 83.33333%;
  }
  .grid-x > .large-11 {
    width: 91.66667%;
  }
  .grid-x > .large-12 {
    width: 100%;
  }
}

.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

@media print, screen and (min-width: 12.5em) {
  .grid-margin-x {
    margin-left: -0.71429rem;
    margin-right: -0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x {
    margin-left: -1.07143rem;
    margin-right: -1.07143rem;
  }
}

.grid-margin-x > .cell {
  width: calc(100% - 1.42857rem);
  margin-left: 0.71429rem;
  margin-right: 0.71429rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .cell {
    width: calc(100% - 2.14286rem);
    margin-left: 1.07143rem;
    margin-right: 1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.42857rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.42857rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.42857rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.42857rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.42857rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.42857rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.42857rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.42857rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.42857rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.42857rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.42857rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.42857rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 2.14286rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 2.14286rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 2.14286rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 2.14286rem);
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-x > .medium-2 {
    width: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 2.14286rem);
  }
  .grid-margin-x > .medium-4 {
    width: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-x > .medium-5 {
    width: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 2.14286rem);
  }
  .grid-margin-x > .medium-7 {
    width: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-x > .medium-8 {
    width: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 2.14286rem);
  }
  .grid-margin-x > .medium-10 {
    width: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-x > .medium-11 {
    width: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 2.14286rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-x > .large-2 {
    width: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-x > .large-3 {
    width: calc(25% - 2.14286rem);
  }
  .grid-margin-x > .large-4 {
    width: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-x > .large-5 {
    width: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-x > .large-6 {
    width: calc(50% - 2.14286rem);
  }
  .grid-margin-x > .large-7 {
    width: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-x > .large-8 {
    width: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-x > .large-9 {
    width: calc(75% - 2.14286rem);
  }
  .grid-margin-x > .large-10 {
    width: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-x > .large-11 {
    width: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-x > .large-12 {
    width: calc(100% - 2.14286rem);
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.71429rem;
    margin-left: -0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -1.07143rem;
    margin-left: -1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.71429rem;
    margin-left: -0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -1.07143rem;
    margin-left: -1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-padding-x > .cell {
    padding-right: 0.71429rem;
    padding-left: 0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-padding-x > .cell {
    padding-right: 1.07143rem;
    padding-left: 1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .small-up-1 > .cell {
    width: 100%;
  }
  .small-up-2 > .cell {
    width: 50%;
  }
  .small-up-3 > .cell {
    width: 33.33333%;
  }
  .small-up-4 > .cell {
    width: 25%;
  }
  .small-up-5 > .cell {
    width: 20%;
  }
  .small-up-6 > .cell {
    width: 16.66667%;
  }
  .small-up-7 > .cell {
    width: 14.28571%;
  }
  .small-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%;
  }
  .medium-up-2 > .cell {
    width: 50%;
  }
  .medium-up-3 > .cell {
    width: 33.33333%;
  }
  .medium-up-4 > .cell {
    width: 25%;
  }
  .medium-up-5 > .cell {
    width: 20%;
  }
  .medium-up-6 > .cell {
    width: 16.66667%;
  }
  .medium-up-7 > .cell {
    width: 14.28571%;
  }
  .medium-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }
  .large-up-2 > .cell {
    width: 50%;
  }
  .large-up-3 > .cell {
    width: 33.33333%;
  }
  .large-up-4 > .cell {
    width: 25%;
  }
  .large-up-5 > .cell {
    width: 20%;
  }
  .large-up-6 > .cell {
    width: 16.66667%;
  }
  .large-up-7 > .cell {
    width: 14.28571%;
  }
  .large-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.42857rem);
  }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.42857rem);
  }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.42857rem);
  }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.42857rem);
  }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.42857rem);
  }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.42857rem);
  }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.42857rem);
  }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.42857rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.42857rem);
  }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.42857rem);
  }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.42857rem);
  }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.42857rem);
  }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.42857rem);
  }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.42857rem);
  }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.42857rem);
  }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.42857rem);
  }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 2.14286rem);
  }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 2.14286rem);
  }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 2.14286rem);
  }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 2.14286rem);
  }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 2.14286rem);
  }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 2.14286rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 2.14286rem);
  }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 2.14286rem);
  }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 2.14286rem);
  }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 2.14286rem);
  }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 2.14286rem);
  }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 2.14286rem);
  }
}

@media print, screen and (min-width: 12.5em) {
  .small-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 12.5em) {
  .small-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .small-3 {
    width: 25%;
  }
  .small-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .small-6 {
    width: 50%;
  }
  .small-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .small-9 {
    width: 75%;
  }
  .small-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .small-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 12.5em) {
  .small-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 12.5em) {
  .small-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .small-offset-0 {
    margin-left: calc(0% + 0.71429rem);
  }
  .small-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .small-offset-1 {
    margin-left: calc(8.33333% + 0.71429rem);
  }
  .small-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .small-offset-2 {
    margin-left: calc(16.66667% + 0.71429rem);
  }
  .small-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .small-offset-3 {
    margin-left: calc(25% + 0.71429rem);
  }
  .small-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .small-offset-4 {
    margin-left: calc(33.33333% + 0.71429rem);
  }
  .small-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .small-offset-5 {
    margin-left: calc(41.66667% + 0.71429rem);
  }
  .small-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .small-offset-6 {
    margin-left: calc(50% + 0.71429rem);
  }
  .small-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .small-offset-7 {
    margin-left: calc(58.33333% + 0.71429rem);
  }
  .small-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .small-offset-8 {
    margin-left: calc(66.66667% + 0.71429rem);
  }
  .small-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .small-offset-9 {
    margin-left: calc(75% + 0.71429rem);
  }
  .small-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .small-offset-10 {
    margin-left: calc(83.33333% + 0.71429rem);
  }
  .small-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .small-offset-11 {
    margin-left: calc(91.66667% + 0.71429rem);
  }
}

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.07143rem);
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 1.07143rem);
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 1.07143rem);
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.07143rem);
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 1.07143rem);
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 1.07143rem);
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.07143rem);
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 1.07143rem);
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 1.07143rem);
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.07143rem);
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 1.07143rem);
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 1.07143rem);
  }
}

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.07143rem);
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 1.07143rem);
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 1.07143rem);
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.07143rem);
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 1.07143rem);
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 1.07143rem);
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.07143rem);
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 1.07143rem);
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 1.07143rem);
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.07143rem);
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 1.07143rem);
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 1.07143rem);
  }
}

.grid-y {
  display: flex;
  flex-flow: column nowrap;
}

.grid-y > .cell {
  width: auto;
}

.grid-y > .auto {
  height: auto;
}

.grid-y > .shrink {
  height: auto;
}

@media print, screen and (min-width: 12.5em) {
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-y > .small-1 {
    height: 8.33333%;
  }
  .grid-y > .small-2 {
    height: 16.66667%;
  }
  .grid-y > .small-3 {
    height: 25%;
  }
  .grid-y > .small-4 {
    height: 33.33333%;
  }
  .grid-y > .small-5 {
    height: 41.66667%;
  }
  .grid-y > .small-6 {
    height: 50%;
  }
  .grid-y > .small-7 {
    height: 58.33333%;
  }
  .grid-y > .small-8 {
    height: 66.66667%;
  }
  .grid-y > .small-9 {
    height: 75%;
  }
  .grid-y > .small-10 {
    height: 83.33333%;
  }
  .grid-y > .small-11 {
    height: 91.66667%;
  }
  .grid-y > .small-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-y > .medium-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.33333%;
  }
  .grid-y > .medium-2 {
    height: 16.66667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.33333%;
  }
  .grid-y > .medium-5 {
    height: 41.66667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.33333%;
  }
  .grid-y > .medium-8 {
    height: 66.66667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.33333%;
  }
  .grid-y > .medium-11 {
    height: 91.66667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.33333%;
  }
  .grid-y > .large-2 {
    height: 16.66667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.33333%;
  }
  .grid-y > .large-5 {
    height: 41.66667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.33333%;
  }
  .grid-y > .large-8 {
    height: 66.66667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.33333%;
  }
  .grid-y > .large-11 {
    height: 91.66667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.71429rem;
    margin-bottom: -0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -1.07143rem;
    margin-bottom: -1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-padding-y > .cell {
    padding-top: 0.71429rem;
    padding-bottom: 0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 1.07143rem;
    padding-bottom: 1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-margin-y {
    margin-top: -0.71429rem;
    margin-bottom: -0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -1.07143rem;
    margin-bottom: -1.07143rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 1.42857rem);
  margin-top: 0.71429rem;
  margin-bottom: 0.71429rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 2.14286rem);
    margin-top: 1.07143rem;
    margin-bottom: 1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.42857rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.42857rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.42857rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.42857rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 2.14286rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 2.14286rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 2.14286rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 2.14286rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 2.14286rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 2.14286rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 2.14286rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 2.14286rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 2.14286rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 2.14286rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 2.14286rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 2.14286rem);
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .medium-grid-frame {
    width: 100%;
  }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
}

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .large-grid-frame {
    width: 100%;
  }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
}

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
}

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-margin-y {
    margin-top: -0.71429rem;
    margin-bottom: -0.71429rem;
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -1.07143rem;
    margin-bottom: -1.07143rem;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 1.42857rem);
  margin-top: 0.71429rem;
  margin-bottom: 0.71429rem;
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 2.14286rem);
    margin-top: 1.07143rem;
    margin-bottom: 1.07143rem;
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.42857rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.42857rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.42857rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.42857rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.42857rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.42857rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 2.14286rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 2.14286rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 2.14286rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 2.14286rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 2.14286rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 2.14286rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 2.14286rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 2.14286rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 2.14286rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 2.14286rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 2.14286rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.33333% - 2.14286rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.66667% - 2.14286rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 2.14286rem);
  }
}

@media print, screen and (min-width: 12.5em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.42857rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 2.14286rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 2.14286rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 2.14286rem);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 2.14286rem);
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.8;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック", "ＭＳ Ｐゴシック", "メイリオ", Meiryo, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca;
}

@media print, screen and (min-width: 12.5em) {
  h1, .h1 {
    font-size: 2.14286rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h2, .h2 {
    font-size: 1.71429rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h3, .h3 {
    font-size: 1.71429rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h4, .h4 {
    font-size: 1.42857rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h5, .h5 {
    font-size: 1.21429rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h6, .h6 {
    font-size: 1.14286rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3.28571rem;
  }
  h2, .h2 {
    font-size: 2.21429rem;
  }
  h3, .h3 {
    font-size: 2.21429rem;
  }
  h4, .h4 {
    font-size: 1.78571rem;
  }
  h5, .h5 {
    font-size: 1.5rem;
  }
  h6, .h6 {
    font-size: 1.28571rem;
  }
}

a {
  line-height: inherit;
  color: #0078D7;
  text-decoration: underline;
  cursor: pointer;
}

a:hover, a:focus {
  color: #0067b9;
  text-decoration: none;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 74.28571rem;
  height: 0;
  margin: 1.42857rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.8;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.64286rem 1.42857rem 0 1.35714rem;
  border-left: 1px solid #cacaca;
}

blockquote, blockquote p {
  line-height: 1.8;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.92857rem;
  color: #8a8a8a;
}

cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.14286rem 0.35714rem 0.07143rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #333;
}

kbd {
  margin: 0;
  padding: 0.14286rem 0.28571rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #333;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.8;
  color: #8a8a8a;
}

.lead {
  font-size: 109.375%;
  line-height: 1.8;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

@media print, screen and (min-width: 12.5em) {
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 3.12857rem;
  margin: 0 0 1.14286rem;
  padding: 0.57143rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(51, 51, 51, 0.1);
  font-family: inherit;
  font-size: 1.14286rem;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fff;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0;
}

input[type='search'] {
  box-sizing: border-box;
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1.14286rem;
}

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.57143rem;
  margin-right: 1.14286rem;
  margin-bottom: 0;
}

[type='checkbox'] + label[for],
[type='radio'] + label[for] {
  cursor: pointer;
}

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.57143rem;
}

[type='file'] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
}

label.middle {
  margin: 0 0 1.14286rem;
  padding: 0.64286rem 0;
}

.help-text {
  margin-top: -0.57143rem;
  font-size: 0.92857rem;
  font-style: italic;
  color: #333;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1.14286rem;
  align-items: stretch;
}

.input-group > :first-child {
  border-radius: 0 0 0 0;
}

.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #333;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.14286rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.57143rem;
}

.fieldset {
  margin: 1.28571rem 0;
  padding: 1.42857rem;
  border: 1px solid #cacaca;
}

.fieldset legend {
  margin: 0;
  margin-left: -0.21429rem;
  padding: 0 0.21429rem;
}

select {
  height: 3.12857rem;
  margin: 0 0 1.14286rem;
  padding: 0.57143rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  font-size: 1.14286rem;
  font-weight: normal;
  line-height: 1.8;
  color: #333;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1.14286rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.71429rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}

select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fff;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb;
}

.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.57143rem;
  margin-bottom: 1.14286rem;
  font-size: 0.85714rem;
  font-weight: bold;
  color: #cc4b37;
}

.form-error.is-visible {
  display: block;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0078D7;
  color: #fff;
}

[data-whatinput='mouse'] .button {
  outline: 0;
}

.button:hover, .button:focus {
  background-color: #0066b7;
  color: #fff;
}

.button.tiny {
  font-size: 0.6rem;
}

.button.small {
  font-size: 0.75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #0078D7;
  color: #fff;
}

.button.primary:hover, .button.primary:focus {
  background-color: #0060ac;
  color: #fff;
}

.button.secondary {
  background-color: #414141;
  color: #fff;
}

.button.secondary:hover, .button.secondary:focus {
  background-color: #343434;
  color: #fff;
}

.button.success {
  background-color: #3adb76;
  color: #333;
}

.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #333;
}

.button.warning {
  background-color: #ffae00;
  color: #333;
}

.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #333;
}

.button.alert {
  background-color: #cc4b37;
  color: #fff;
}

.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #fff;
}

.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #0078D7;
  color: #fff;
}

.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #0078D7;
  color: #fff;
}

.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #414141;
  color: #fff;
}

.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #333;
}

.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #333;
}

.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fff;
}

.button.hollow {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}

.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent;
}

.button.hollow:hover, .button.hollow:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow.primary {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.hollow.secondary {
  border: 1px solid #414141;
  color: #414141;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #212121;
  color: #212121;
}

.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #414141;
  color: #414141;
}

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent;
}

.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent;
}

.button.clear:hover, .button.clear:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent;
}

.button.clear.primary {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #003c6c;
  color: #003c6c;
}

.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid #0078D7;
  color: #0078D7;
}

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent;
}

.button.clear.secondary {
  border: 1px solid #414141;
  color: #414141;
}

.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #212121;
  color: #212121;
}

.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #414141;
  color: #414141;
}

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent;
}

.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent;
}

.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent;
}

.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent;
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fff transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.dropdown.hollow::after {
  border-top-color: #0078D7;
}

.button.dropdown.hollow.primary::after {
  border-top-color: #0078D7;
}

.button.dropdown.hollow.secondary::after {
  border-top-color: #414141;
}

.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}

.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

.accordion {
  margin-left: 0;
  background: #fff;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.85714rem;
  line-height: 1;
  color: #0078D7;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+';
}

.is-active > .accordion-title::before {
  content: '\2013';
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fff;
  color: #333;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.accordion-menu li {
  width: 100%;
}

.accordion-menu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem;
}

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0078D7 transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem;
}

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem;
}

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem;
}

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.is-accordion-submenu-parent {
  position: relative;
}

.has-submenu-toggle > a {
  margin-right: 40px;
}

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0078D7 transparent transparent;
  top: 0;
  bottom: 0;
  margin: auto;
}

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%;
}

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #0078D7;
  color: #fff;
}

.badge.primary {
  background: #0078D7;
  color: #fff;
}

.badge.secondary {
  background: #414141;
  color: #fff;
}

.badge.success {
  background: #3adb76;
  color: #333;
}

.badge.warning {
  background: #ffae00;
  color: #333;
}

.badge.alert {
  background: #cc4b37;
  color: #fff;
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}

.breadcrumbs::before, .breadcrumbs::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.breadcrumbs::after {
  clear: both;
}

.breadcrumbs li {
  float: left;
  font-size: 0.78571rem;
  color: #333;
  cursor: default;
}

.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: #cacaca;
}

.breadcrumbs a {
  color: #0078D7;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

.button-group::before, .button-group::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.button-group::after {
  clear: both;
}

.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem;
  flex: 0 0 auto;
}

.button-group .button:last-child {
  margin-right: 0;
}

.button-group.tiny .button {
  font-size: 0.6rem;
}

.button-group.small .button {
  font-size: 0.75rem;
}

.button-group.large .button {
  font-size: 1.25rem;
}

.button-group.expanded .button {
  flex: 1 1 0px;
}

.button-group.primary .button {
  background-color: #0078D7;
  color: #fff;
}

.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #0060ac;
  color: #fff;
}

.button-group.secondary .button {
  background-color: #414141;
  color: #fff;
}

.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #343434;
  color: #fff;
}

.button-group.success .button {
  background-color: #3adb76;
  color: #333;
}

.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #333;
}

.button-group.warning .button {
  background-color: #ffae00;
  color: #333;
}

.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #333;
}

.button-group.alert .button {
  background-color: #cc4b37;
  color: #fff;
}

.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #fff;
}

.button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
  flex-wrap: wrap;
}

.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}

.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 0;
  background-color: white;
  color: #333;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #d3ebff;
  color: #333;
}

.callout.secondary {
  background-color: #e3e3e3;
  color: #333;
}

.callout.success {
  background-color: #e1faea;
  color: #333;
}

.callout.warning {
  background-color: #fff3d9;
  color: #333;
}

.callout.alert {
  background-color: #f7e4e1;
  color: #333;
}

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  overflow: hidden;
  color: #333;
}

.card > :last-child {
  margin-bottom: 0;
}

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6;
}

.card-divider > :last-child {
  margin-bottom: 0;
}

.card-section {
  flex: 1 0 auto;
  padding: 1rem;
}

.card-section > :last-child {
  margin-bottom: 0;
}

.card-image {
  min-height: 1px;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}

[data-whatinput='mouse'] .close-button {
  outline: 0;
}

.close-button:hover, .close-button:focus {
  color: #333;
}

.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}

.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

[data-whatinput='mouse'] .menu li {
  outline: 0;
}

.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}

.menu input {
  display: inline-block;
}

.menu, .menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}

.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}

.menu.expanded li {
  flex: 1 1 0px;
}

.menu.simple {
  align-items: center;
}

.menu.simple li + li {
  margin-left: 1rem;
}

.menu.simple a {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}

.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}

.menu.icons a {
  display: flex;
}

.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: flex;
}

.menu.icon-left li a {
  flex-flow: row nowrap;
}

.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}

.menu.icon-right li a {
  flex-flow: row nowrap;
}

.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}

.menu.icon-top li a {
  flex-flow: column nowrap;
}

.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu.icon-bottom li a {
  flex-flow: column nowrap;
}

.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu .is-active > a {
  background: #0078D7;
  color: #fff;
}

.menu .active > a {
  background: #0078D7;
  color: #fff;
}

.menu.align-left {
  justify-content: flex-start;
}

.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}

.menu.align-right li .submenu li {
  justify-content: flex-start;
}

.menu.align-right.vertical li {
  display: block;
  text-align: right;
}

.menu.align-right.vertical li .submenu li {
  text-align: right;
}

.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}

.menu.align-center li {
  display: flex;
  justify-content: center;
}

.menu.align-center li .submenu li {
  justify-content: flex-start;
}

.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered > .menu {
  justify-content: center;
}

.menu-centered > .menu li {
  display: flex;
  justify-content: center;
}

.menu-centered > .menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
  content: '';
}

.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #333;
  box-shadow: 0 7px 0 #333, 0 14px 0 #333;
  content: '';
}

.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}

.is-drilldown {
  position: relative;
  overflow: hidden;
}

.is-drilldown li {
  display: block;
}

.is-drilldown.animate-height {
  transition: height 0.5s;
}

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fff;
}

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fff;
  transition: transform 0.15s linear;
}

.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  transform: translateX(-100%);
}

.drilldown .is-drilldown-submenu.is-closing {
  transform: translateX(100%);
}

.drilldown .is-drilldown-submenu a {
  padding: 0.7rem 1rem;
}

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%;
}

.drilldown .is-drilldown-submenu-parent > a {
  position: relative;
}

.drilldown .is-drilldown-submenu-parent > a::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
}

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem;
}

.dropdown-pane.is-opening {
  display: block;
}

.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0078D7 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px;
}

.dropdown.menu a {
  padding: 0.7rem 1rem;
}

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0;
}

.dropdown.menu .is-active > a {
  background: transparent;
  color: #0078D7;
}

.no-js .dropdown.menu ul {
  display: none;
}

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.dropdown.menu.vertical > li > a::after {
  right: 14px;
}

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
}

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0078D7 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0078D7 transparent transparent;
  }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0078D7;
  }
}

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0078D7 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0078D7 transparent transparent;
  }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0078D7;
  }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fff;
}

.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem;
}

.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0078D7 transparent transparent;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0078D7;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}

.is-dropdown-submenu > li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1.14286rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #0078D7;
  color: #fff;
}

.label.primary {
  background: #0078D7;
  color: #fff;
}

.label.secondary {
  background: #414141;
  color: #fff;
}

.label.success {
  background: #3adb76;
  color: #333;
}

.label.warning {
  background: #ffae00;
  color: #333;
}

.label.alert {
  background: #cc4b37;
  color: #fff;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}

.media-object img {
  max-width: none;
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}

.media-object-section:first-child {
  padding-right: 1rem;
}

.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}

.media-object-section > :last-child {
  margin-bottom: 0;
}

.media-object-section.main-section {
  flex: 1 1 0px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}

.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}

.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}

.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput='mouse'] .off-canvas {
  outline: 0;
}

.off-canvas.is-transition-push {
  z-index: 12;
}

.off-canvas.is-closed {
  visibility: hidden;
}

.off-canvas.is-transition-overlap {
  z-index: 13;
}

.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.7);
}

.off-canvas.is-open {
  transform: translate(0, 0);
}

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput='mouse'] .off-canvas-absolute {
  outline: 0;
}

.off-canvas-absolute.is-transition-push {
  z-index: 12;
}

.off-canvas-absolute.is-closed {
  visibility: hidden;
}

.off-canvas-absolute.is-transition-overlap {
  z-index: 13;
}

.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.7);
}

.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-left {
    width: 250px;
    transform: translateX(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-left {
    width: 1024px;
    transform: translateX(-1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-1024px);
  }
}

.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(1024px);
  }
}

.position-left.is-transition-push {
  box-shadow: inset -13px 0 20px -13px rgba(51, 51, 51, 0.25);
}

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-right {
    width: 250px;
    transform: translateX(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-right {
    width: 1024px;
    transform: translateX(1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(1024px);
  }
}

.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-1024px);
  }
}

.position-right.is-transition-push {
  box-shadow: inset 13px 0 20px -13px rgba(51, 51, 51, 0.25);
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-top {
    height: 250px;
    transform: translateY(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-top {
    height: 1024px;
    transform: translateY(-1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-1024px);
  }
}

.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(1024px);
  }
}

.position-top.is-transition-push {
  box-shadow: inset 0 -13px 20px -13px rgba(51, 51, 51, 0.25);
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
}

@media print, screen and (min-width: 12.5em) {
  .position-bottom {
    height: 250px;
    transform: translateY(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .position-bottom {
    height: 1024px;
    transform: translateY(1024px);
  }
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(1024px);
  }
}

.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 12.5em) {
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px);
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-1024px);
  }
}

.position-bottom.is-transition-push {
  box-shadow: inset 0 13px 20px -13px rgba(51, 51, 51, 0.25);
}

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.off-canvas-content.has-transition-push {
  transform: translate(0, 0);
}

.off-canvas-content .off-canvas.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 1024px;
  }
  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 1024px;
  }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 1024px;
  }
  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 1024px;
  }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 1024px;
  }
  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 1024px;
  }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 1024px;
  }
  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 1024px;
  }
}

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 1024px;
  }
  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 1024px;
  }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 1024px;
  }
  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 1024px;
  }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 1024px;
  }
  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 1024px;
  }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 1024px;
  }
  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 1024px;
  }
}

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-medium .close-button {
    display: none;
  }
}

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-large .close-button {
    display: none;
  }
}

.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  color: #fff;
}

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fff;
}

[data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
  outline: 0;
}

.orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
  background-color: rgba(51, 51, 51, 0.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

[data-whatinput='mouse'] .orbit-bullets {
  outline: 0;
}

.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #cacaca;
}

.orbit-bullets button:hover {
  background-color: #8a8a8a;
}

.orbit-bullets button.is-active {
  background-color: #8a8a8a;
}

.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}

.pagination::before, .pagination::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.pagination::after {
  clear: both;
}

.pagination li {
  margin-right: 0.07143rem;
  border-radius: 0;
  font-size: 1rem;
  display: none;
}

.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}

@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}

.pagination a,
.pagination button {
  display: block;
  padding: 0.21429rem 0.71429rem;
  border-radius: 0;
  color: #333;
}

.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}

.pagination .current {
  padding: 0.21429rem 0.71429rem;
  background: #0078D7;
  color: #fff;
  cursor: default;
}

.pagination .disabled {
  padding: 0.21429rem 0.71429rem;
  color: #cacaca;
  cursor: not-allowed;
}

.pagination .disabled:hover {
  background: transparent;
}

.pagination .ellipsis::after {
  padding: 0.21429rem 0.71429rem;
  content: '\2026';
  color: #333;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab';
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb';
}

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca;
}

.progress.primary .progress-meter {
  background-color: #0078D7;
}

.progress.secondary .progress-meter {
  background-color: #414141;
}

.progress.success .progress-meter {
  background-color: #3adb76;
}

.progress.warning .progress-meter {
  background-color: #ffae00;
}

.progress.alert .progress-meter {
  background-color: #cc4b37;
}

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #0078D7;
}

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
}

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out;
}

.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #0078D7;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}

[data-whatinput='mouse'] .slider-handle {
  outline: 0;
}

.slider-handle:hover {
  background-color: #0066b7;
}

.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}

.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}

.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

.sticky-container {
  position: relative;
}

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.sticky.is-stuck.is-at-top {
  top: 0;
}

.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}

.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(51, 51, 51, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .columns {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 74.28571rem;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 74.28571rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 74.28571rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 74.28571rem;
  }
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}

input + .switch-paddle {
  margin: 0;
}

.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #fff;
  transition: all 0.25s ease-out;
  content: '';
}

input:checked ~ .switch-paddle {
  background: #0078D7;
}

input:checked ~ .switch-paddle::after {
  left: 2.25rem;
}

[data-whatinput='mouse'] input:focus ~ .switch-paddle {
  outline: 0;
}

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}

input:checked + label > .switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}

input:checked + label > .switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}

.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.71429rem;
}

.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}

.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.85714rem;
}

.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}

.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}

.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1.14286rem;
}

.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}

.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}

table thead,
table tbody,
table tfoot {
  border: 1px solid #f2f2f2;
  background-color: #fff;
}

table caption {
  padding: 0.57143rem 0.71429rem 0.71429rem;
  font-weight: bold;
}

table thead {
  background: #e8e8e8;
  color: #333;
}

table tfoot {
  background: #f2f2f2;
  color: #333;
}

table thead tr,
table tfoot tr {
  background: transparent;
}

table thead th,
table thead td,
table tfoot th,
table tfoot td {
  padding: 0.57143rem 0.71429rem 0.71429rem;
  font-weight: bold;
  text-align: left;
}

table tbody th,
table tbody td {
  padding: 0.57143rem 0.71429rem 0.71429rem;
}

table tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f2f2f2;
}

table.unstriped tbody {
  background-color: #fff;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
}

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #e3e3e3;
}

table.hover tfoot tr:hover {
  background-color: #ededed;
}

table.hover tbody tr:hover {
  background-color: #fafafa;
}

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed;
}

.table-scroll {
  overflow-x: auto;
}

.table-scroll table {
  width: auto;
}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fff;
  list-style-type: none;
}

.tabs::before, .tabs::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}

.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #0078D7;
}

.tabs.primary > li > a {
  color: #fff;
}

.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #0072cc;
}

.tabs-title {
  float: left;
}

.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 0.85714rem;
  line-height: 1;
  color: #0078D7;
}

.tabs-title > a:hover {
  background: #fff;
  color: #0067b9;
}

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #e6e6e6;
  color: #0078D7;
}

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fff;
  color: #333;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}

.tabs-panel.is-active {
  display: block;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(51, 51, 51, 0.2);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}

a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(0, 120, 215, 0.5);
}

a.thumbnail image {
  box-shadow: none;
}

.title-bar {
  padding: 0.5rem;
  background: #333;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #333;
  font-size: 80%;
  color: #fff;
}

.tooltip::before {
  position: absolute;
}

.tooltip.bottom::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #333;
  bottom: 100%;
}

.tooltip.bottom.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #333 transparent transparent;
  top: 100%;
  bottom: auto;
}

.tooltip.top.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #333;
  left: 100%;
}

.tooltip.left.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #333 transparent transparent;
  right: 100%;
  left: auto;
}

.tooltip.right.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.align-top::before {
  bottom: auto;
  top: 10%;
}

.tooltip.align-bottom::before {
  bottom: 10%;
  top: auto;
}

.tooltip.align-left::before {
  left: 10%;
  right: auto;
}

.tooltip.align-right::before {
  left: auto;
  right: 10%;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}

.top-bar,
.top-bar ul {
  background-color: #e6e6e6;
}

.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}

.top-bar input.button {
  width: auto;
}

.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
    margin-right: auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 12.4375em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media print, screen and (min-width: 12.5em) {
  .small-order-1 {
    order: 1;
  }
  .small-order-2 {
    order: 2;
  }
  .small-order-3 {
    order: 3;
  }
  .small-order-4 {
    order: 4;
  }
  .small-order-5 {
    order: 5;
  }
  .small-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

.text-hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;
}

.text-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  word-wrap: break-word !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-overline {
  text-decoration: overline !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.font-wide {
  letter-spacing: 0.28571rem;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic !important;
}

ul.list-disc {
  list-style-type: disc !important;
}

ul.list-circle {
  list-style-type: circle !important;
}

ul.list-square {
  list-style-type: square !important;
}

ol.list-decimal {
  list-style-type: decimal !important;
}

ol.list-lower-alpha {
  list-style-type: lower-alpha !important;
}

ol.list-lower-latin {
  list-style-type: lower-latin !important;
}

ol.list-lower-roman {
  list-style-type: lower-roman !important;
}

ol.list-upper-alpha {
  list-style-type: upper-alpha !important;
}

ol.list-upper-latin {
  list-style-type: upper-latin !important;
}

ol.list-upper-roman {
  list-style-type: upper-roman !important;
}

.rounded {
  border-radius: 5000px !important;
}

.radius {
  border-radius: 0.21429rem;
}

.bordered {
  border: 0.07143rem solid #cacaca;
}

.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #333 transparent transparent;
}

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #333;
}

.arrow-right {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #333;
}

.arrow-left {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #333 transparent transparent;
}

.separator-center {
  text-align: center !important;
}

.separator-center::before, .separator-center::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.separator-center::after {
  clear: both;
}

.separator-center::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.14286rem solid #0078D7;
  margin: 1rem auto 0;
}

.separator-left {
  text-align: left !important;
}

.separator-left::before, .separator-left::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.separator-left::after {
  clear: both;
}

.separator-left::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.14286rem solid #0078D7;
  margin: 1rem auto 0;
  margin-left: 0 !important;
}

.separator-right {
  text-align: right !important;
}

.separator-right::before, .separator-right::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.separator-right::after {
  clear: both;
}

.separator-right::after {
  position: relative !important;
  width: 3rem;
  border-bottom: 0.14286rem solid #0078D7;
  margin: 1rem auto 0;
  margin-right: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.display-table {
  display: table !important;
}

.display-table-cell {
  display: table-cell !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975;
}

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975;
}

.border-box {
  box-sizing: border-box !important;
}

.border-none {
  border: none !important;
}

.width-25 {
  width: 25% !important;
}

.width-50 {
  width: 50% !important;
}

.width-75 {
  width: 75% !important;
}

.width-100 {
  width: 100% !important;
}

.height-25 {
  height: 25% !important;
}

.height-50 {
  height: 50% !important;
}

.height-75 {
  height: 75% !important;
}

.height-100 {
  height: 100% !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.max-height-100 {
  max-height: 100% !important;
}

.margin-0 {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
}

.padding-0 {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
}

.margin-top-0 {
  margin-top: 0rem !important;
}

.padding-top-0 {
  padding-top: 0rem !important;
}

.margin-right-0 {
  margin-right: 0rem !important;
}

.padding-right-0 {
  padding-right: 0rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0rem !important;
}

.padding-bottom-0 {
  padding-bottom: 0rem !important;
}

.margin-left-0 {
  margin-left: 0rem !important;
}

.padding-left-0 {
  padding-left: 0rem !important;
}

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.margin-1 {
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
}

.padding-1 {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
}

.margin-top-1 {
  margin-top: 1rem !important;
}

.padding-top-1 {
  padding-top: 1rem !important;
}

.margin-right-1 {
  margin-right: 1rem !important;
}

.padding-right-1 {
  padding-right: 1rem !important;
}

.margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.padding-bottom-1 {
  padding-bottom: 1rem !important;
}

.margin-left-1 {
  margin-left: 1rem !important;
}

.padding-left-1 {
  padding-left: 1rem !important;
}

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.margin-2 {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 2rem !important;
}

.padding-2 {
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important;
}

.margin-top-2 {
  margin-top: 2rem !important;
}

.padding-top-2 {
  padding-top: 2rem !important;
}

.margin-right-2 {
  margin-right: 2rem !important;
}

.padding-right-2 {
  padding-right: 2rem !important;
}

.margin-bottom-2 {
  margin-bottom: 2rem !important;
}

.padding-bottom-2 {
  padding-bottom: 2rem !important;
}

.margin-left-2 {
  margin-left: 2rem !important;
}

.padding-left-2 {
  padding-left: 2rem !important;
}

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.margin-3 {
  margin-top: 3rem !important;
  margin-right: 3rem !important;
  margin-bottom: 3rem !important;
  margin-left: 3rem !important;
}

.padding-3 {
  padding-top: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 3rem !important;
}

.margin-top-3 {
  margin-top: 3rem !important;
}

.padding-top-3 {
  padding-top: 3rem !important;
}

.margin-right-3 {
  margin-right: 3rem !important;
}

.padding-right-3 {
  padding-right: 3rem !important;
}

.margin-bottom-3 {
  margin-bottom: 3rem !important;
}

.padding-bottom-3 {
  padding-bottom: 3rem !important;
}

.margin-left-3 {
  margin-left: 3rem !important;
}

.padding-left-3 {
  padding-left: 3rem !important;
}

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.breadcrumbs a:focus, .breadcrumbs a:hover {
  text-decoration: none;
}

.button {
  text-decoration: none;
}

.button:visited {
  color: #fff;
}

.page-header .page-title-inner h1 {
  margin-bottom: 0;
}

.l_list-style {
  list-style: none;
}

.l_list-style li {
  margin-bottom: 0.5em;
}

.l_list-style li:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #0078D7;
  margin-right: 1em;
}

.l_list-style li.root-detail:before {
  content: "";
  display: none;
}

footer {
  background-color: #414141;
  color: #fff;
}

footer .footer-logo {
  width: 300px;
  margin: 0 auto;
}

footer .footer-logo a {
  display: block;
  padding: 1.5em;
}

footer .footer-logo a img {
  height: 100%;
}

footer address p {
  margin-bottom: 0;
}

footer address p span.responsible {
  font-weight: bold;
}

footer address a {
  text-decoration: none;
  color: #fff;
}

.header {
  padding: 0 0 0 1em;
  border-bottom: 2px solid #0078D7;
}

.header-inner {
  position: relative;
  max-width: 1090px;
  margin: auto;
}

@media screen and (max-width: 39.9375em) {
  .header-inner {
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
}

@media print, screen and (min-width: 40em) {
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: nowrap;
  }
  .header-inner nav {
    flex: 1 1 auto;
  }
}

.header-inner li:last-of-type a {
  color: #fff;
}

.header-inner li a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.header-inner li:not(:nth-last-of-type(2)) span {
  border-right: 1px solid #95989A;
}

@media print, screen and (min-width: 64em) {
  .header-inner li:nth-last-of-type(2) {
    padding-left: 1em;
  }
}

.header-inner li span {
  padding-right: 2rem;
}

.header-inner li:nth-of-type(3) a {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 64em) {
  .header-inner li:last-of-type a {
    padding: 0 1.5rem;
  }
}

.header-inner h1 a {
  font-weight: bold;
}

@media print, screen and (min-width: 64em) {
  .header-inner h1 a {
    min-height: 74px;
  }
}

@media print, screen and (min-width: 40em) {
  .header-inner h1 a {
    width: 300px;
  }
}

@media screen and (max-width: 39.9375em) {
  .header-inner h1 a {
    width: 250px;
  }
}

@media screen and (max-width: 63.9375em) {
  .header-inner h1 a img {
    width: 90%;
  }
}

@media print, screen and (min-width: 64em) {
  .header-inner h1 a img {
    width: 100%;
    padding: 1em 0;
  }
}

.header-inner .header-button-container button {
  position: relative;
  padding: 0.3em 0.7em 0;
}

.header-inner .header-button-container button span:nth-of-type(1) {
  width: 80%;
  height: 2px;
  background: #fff;
  display: block;
  margin: 5px auto;
}

.header-inner .header-button-container button span:nth-of-type(2) {
  width: 80%;
  height: 2px;
  background: #fff;
  display: block;
  margin: 5px auto;
}

.header-inner .header-button-container button span:nth-of-type(3) {
  width: 80%;
  height: 2px;
  background: #fff;
  display: block;
  margin: 5px auto;
}

.header-inner .header-button-container button p {
  color: #fff;
  margin: 0;
  font-weight: bold;
}

.header-inner nav.sp-menu-content {
  width: 230px;
  background-color: #0078D7;
}

.header-inner nav.sp-menu-content ul li:not(:first-of-type) {
  border-bottom: 2px dotted #fff;
}

.header-inner nav.sp-menu-content ul li:not(:first-of-type) a {
  color: #fff;
}

.header-inner nav.sp-menu-content ul li:not(:first-of-type) a span {
  padding: 0;
  border: none;
}

.header-inner nav.sp-menu-content ul li:not(:first-of-type) a:hover {
  color: #0078D7;
  background-color: #fff;
}

.header-inner nav.sp-menu-content ul li:first-of-type {
  background-color: #fff;
  border-bottom: 2px dotted #fff;
}

.header-inner nav.sp-menu-content ul li:first-of-type a {
  padding: 1.5em 0.5em;
}

.header-logo {
  margin-bottom: 0;
  font-size: 100%;
  display: flex;
  align-items: center;
}

.header-button-container button {
  background-color: #0078D7;
  cursor: pointer;
}

.header-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.header-nav a {
  cursor: pointer;
  display: block;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 63.9375em) {
  .header-nav a {
    justify-content: center;
  }
}

.header-nav a:hover .slide-color:before {
  width: 100%;
}

.header-nav a .slide-color {
  font-weight: bold;
  display: inline-block;
  outline: medium none;
  text-decoration: none;
  position: relative;
}

.header-nav a .slide-color:before {
  content: "";
  border-bottom: 2px solid;
  color: #0078D7;
  text-shadow: 0 0 1px #fff;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0;
  width: 0;
  position: absolute;
  left: -1rem;
  transition: width 0.2s ease 0s;
}

@media print, screen and (min-width: 64em) {
  .header-nav a .slide-color:before {
    content: attr(data-hover);
    text-shadow: none;
  }
}

@media screen and (max-width: 63.9375em) {
  .header-nav a .slide-color:before {
    left: 0;
    bottom: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .header-nav {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
  }
}

.header-nav > li a {
  display: flex;
  align-items: center;
  padding: .5em 1em;
  height: 100%;
}

@media screen and (max-width: 63.9375em) {
  .header-nav > li a {
    padding: 1em 0;
  }
}

@media print, screen and (min-width: 64em) {
  .sp-only {
    display: none;
  }
}

@media screen and (max-width: 63.9375em) {
  .pc-only {
    display: none;
  }
}

main {
  position: relative;
}

main .footer-link-top {
  position: fixed;
  bottom: 1em;
  right: 2em;
}

main .footer-link-top a.button {
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 50%;
  background-color: #0078D7;
  border: 1px solid #0078D7;
}

main .footer-link-top a.button:after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(-135deg);
}

main .footer-link-top a.button:hover {
  background: #fff;
}

main .footer-link-top a.button:hover:after {
  border-color: #0078D7;
}

main .page-contents .page-intro {
  margin: 3em 1em;
}

main .page-contents .page-intro h2 {
  margin-bottom: 1em;
}

main .page-contents .page-intro .intro-text {
  font-weight: bold;
  font-size: 1.42857rem;
  line-height: 1.8;
}

@media screen and (max-width: 63.9375em) {
  main .page-contents .page-intro .intro-text {
    font-size: 1.28571rem;
  }
}

main ul.arrow-link {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 2em 0 0 0;
}

@media screen and (max-width: 63.9375em) {
  main ul.arrow-link {
    display: block;
  }
}

main ul.arrow-link li {
  border: 2px solid #95989A;
  border-radius: 5px;
  position: relative;
  transition: 0.2s;
}

main ul.arrow-link li:after {
  content: "";
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border-right: 1px solid #95989A;
  border-bottom: 1px solid #95989A;
  transform: rotate(-45deg);
}

main ul.arrow-link li:hover {
  transition: 0.2s;
  background-color: #95989A;
}

main ul.arrow-link li:hover a {
  color: #fff;
}

main ul.arrow-link li:hover:after {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

main ul.arrow-link li a {
  position: relative;
  text-decoration: none;
  font-size: 1.28571rem;
  display: block;
  text-align: center;
  padding: 0.2em 2em;
  color: #333;
}

@media screen and (max-width: 63.9375em) {
  main ul.arrow-link li:first-of-type {
    margin: 0 0 1em 0;
  }
}

main .arrow-link.root {
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 3em;
}

main .arrow-link.root li:first-of-type {
  margin: 0;
}

@media screen and (max-width: 63.9375em) {
  main .arrow-link.root {
    width: 80%;
    margin: auto;
  }
}

main aside.banner-list p a {
  transition: 0.2s;
}

main aside.banner-list p a:hover {
  transition: 0.2s;
  opacity: 0.6;
}

main .corse-text {
  line-height: 2.5;
}

main .corse-text ul li {
  line-height: 2.5;
}

.site-logo {
  transition: 0.2s;
}

.site-logo:hover {
  transition: 0.2s;
  opacity: 0.6;
}

body.home .header {
  margin-bottom: 0;
}

body.home article.main-wrap {
  position: relative;
}

body.home article.main-wrap p {
  margin: 0;
}

body.home article.main-wrap p.main-text img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto;
  width: 400px;
  height: 250px;
}

@media screen and (max-width: 63.9375em) {
  body.home article.main-wrap p.main-text img {
    width: 50%;
    height: auto;
    right: 10%;
    left: auto;
  }
}

body.home article.main-wrap:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #0078D7;
}

body.home article.intro-wrap {
  text-align: center;
  color: #fff;
  background: url(../img/common/home-intro-bg.png);
  padding: 4em 0;
}

body.home article.intro-wrap .inner.row h1 {
  font-size: 2.21429rem;
}

body.home article.intro-wrap .inner.row .intro-text {
  font-size: 1.28571rem;
}

body.home article.intro-wrap .inner.row figure {
  position: relative;
}

@media screen and (max-width: 63.9375em) {
  body.home article.intro-wrap .inner.row figure {
    margin-bottom: 6em;
  }
}

body.home article.intro-wrap .inner.row figure img {
  width: 200px;
}

body.home article.intro-wrap .inner.row figure figcaption {
  margin-top: 1em;
}

body.home article.intro-wrap .inner.row figure:not(:last-of-type):after {
  content: "";
  display: block;
  position: absolute;
  top: 30%;
  right: -10px;
  width: 0;
  height: 0;
  border: 1em solid #fff;
  border-top: 1em solid transparent;
  border-left: 1em solid transparent;
  transform: rotate(-45deg);
}

@media screen and (max-width: 39.9375em) {
  body.home article.intro-wrap .inner.row figure:not(:last-of-type):after {
    bottom: -90%;
    right: 0;
    left: 0;
    margin: auto;
    transform: rotate(45deg);
  }
}

body.home article.intro-wrap .inner.row .step {
  margin: 3em auto 0;
}

body.home article.root p {
  margin: 0;
}

body.home article.root p img {
  width: 100%;
}

body.home article.root .root-block {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 63.9375em) {
  body.home article.root .root-block {
    padding: 3em 0;
  }
}

@media screen and (max-width: 39.9375em) {
  body.home article.root .root-block {
    display: block;
    padding: 3em 1em;
  }
}

body.home article.root .root-block h2 {
  margin: 0 auto 1.5em;
}

body.home article.root .root-block .inner {
  margin: auto;
}

body.home article.price p {
  margin: 0;
}

body.home article.price p img {
  width: 100%;
}

body.home article.price .price-block {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 63.9375em) {
  body.home article.price .price-block {
    padding: 3em 0;
  }
}

@media screen and (max-width: 39.9375em) {
  body.home article.price .price-block {
    display: block;
    padding: 3em 1em;
  }
}

body.home article.price .price-block h2 {
  margin: 0 auto 1.5em;
}

body.home article.price .price-block .inner {
  margin: auto;
}

body.home aside.banner-list {
  margin-bottom: 4em;
}

body.home aside.banner-list h2 {
  margin: 2em 0;
}

body.home .slick-arrow {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  cursor: pointer;
  outline: 0;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  top: 50%;
  margin-top: -30px;
}

body.home .slick-arrow:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 15px;
  border-top: 3px solid #FFF;
  border-left: 3px solid #FFF;
}

body.home .slick-arrow.slick-prev {
  left: 10%;
  margin-left: -15px;
}

body.home .slick-arrow.slick-prev:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: 3px;
}

body.home .slick-arrow.slick-next {
  right: 10%;
  margin-right: -15px;
}

body.home .slick-arrow.slick-next:after {
  transform: translate(-50%, -50%) rotate(135deg);
  margin-left: -3px;
}

body.home .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 20px 0;
  padding: 0;
}

body.home .slick-dots > li {
  margin: 0 4px;
  display: flex;
  align-items: center;
}

body.home .slick-dots > li button {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  border-radius: 50%;
  background-color: #999999;
  width: 0.57143rem;
  height: 0.57143rem;
  cursor: pointer;
}

body.home .slick-dots > li.slick-active button {
  background-color: #0078D7;
}

.recommend-root-list article {
  margin-bottom: 3em;
}

.recommend-root-list .page-header {
  background: url(../img/lower/recommend-header-bg.png) no-repeat;
  background-size: cover;
  height: 145px;
  color: #fff;
  display: flex;
  align-items: center;
}

.recommend-root-list .page-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #0078D7;
}

body.jositei-detail .page-header h1 {
  font-size: 2.14286rem;
}

@media screen and (max-width: 39.9375em) {
  body.jositei-detail .page-header h1 {
    font-size: 1.57143rem;
  }
}

body.recommend p.back-button-wrap {
  text-align: center;
  margin-bottom: 3em;
}

body.recommend p.back-button-wrap a.back-button {
  background-color: #95989A;
  color: #fff;
  text-decoration: none;
  padding: 1em 1.5em;
  border-radius: 4px;
  margin-bottom: 0;
}

body.recommend p.back-button-wrap a.back-button:hover {
  background-color: #7b7f81;
}

body.recommend p.back-button-wrap a.back-button:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(135deg);
  vertical-align: bottom;
  margin-right: 1em;
}

body.recommend .recommend-wrap h2:before {
  content: "";
  display: inline-block;
  background: url(../img/lower/icon_route.svg) no-repeat;
  width: 50px;
  height: 50px;
  margin-right: 0.5em;
}

body.recommend .recommend-wrap h2 {
  margin-bottom: 1em;
}

body.recommend .recommend-wrap .four_h {
  margin-top: 2em;
}

body.recommend .page-contents {
  max-width: 74.28571rem;
  margin: auto;
}

@media print, screen and (min-width: 64em) {
  body.recommend .page-contents .l_content {
    display: flex;
  }
}

@media screen and (max-width: 63.9375em) {
  body.recommend .page-contents .l_content .image-wrap {
    margin-top: 2em;
  }
}

body.recommend .page-contents .l_content .text-wrap {
  align-self: flex-start;
}

body.recommend .page-contents .l_content h3 {
  background: #0078D7;
  color: #fff;
  position: relative;
  border-radius: 5px;
  padding: 0.2em 0.5em;
  font-size: 1.78571rem;
  margin-bottom: 0.5em;
}

body.recommend .page-contents .l_content h3:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top: 15px solid #0078D7;
  left: 40px;
}

body.recommend .page-contents .l_content .root li:first-of-type {
  display: none;
}

body.recommend .page-contents .l_content .root li:last-of-type {
  border: 2px solid #0078D7;
  border-radius: 5px;
  position: relative;
  transition: .2s;
}

body.recommend .page-contents .l_content .root li:last-of-type:after {
  content: "";
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  border-right: 1px solid #0078D7;
  border-bottom: 1px solid #0078D7;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.recommend .page-contents .l_content .root li:last-of-type:hover {
  background-color: #0078D7;
}

body.recommend .page-contents .l_content .root li:last-of-type:hover:after {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

body.root-detail .page-header {
  background: url(../img/lower/root_detail-header-bg.png) no-repeat;
  background-size: cover;
  height: 145px;
  color: #fff;
  display: flex;
  align-items: center;
}

body.root-detail .page-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #0078D7;
}

body.root-detail .page-intro h2 span {
  background: linear-gradient(transparent 80%, rgba(0, 120, 215, 0.6) 0%);
}

body.root-detail section.l_content {
  position: relative;
}

body.root-detail section.l_content:nth-of-type(even) {
  background-color: #F5F5F5;
}

body.root-detail section.l_content:nth-of-type(even):after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  margin: auto;
  border: 40px solid transparent;
  border-top: 40px solid #fff;
}

body.root-detail section.l_content:nth-of-type(even):before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  width: 0;
  height: 0;
  margin: auto;
  border: 40px solid transparent;
  border-top: 40px solid #F5F5F5;
}

body.root-detail section.l_content .l_inner {
  position: relative;
  padding: 5em 0;
}

@media print, screen and (min-width: 64em) {
  body.root-detail section.l_content .l_inner {
    display: flex;
  }
}

body.root-detail section.l_content .l_inner h2:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #0078D7;
  margin-right: 0.5em;
}

@media screen and (max-width: 63.9375em) {
  body.root-detail section.l_content .l_inner h2:before {
    vertical-align: text-bottom;
  }
}

body.root-detail section.l_content .l_inner .image-wrap {
  margin: 1em 0;
}

@media print, screen and (min-width: 64em) {
  body.root-detail section.l_content .l_inner .image-wrap {
    align-self: center;
  }
}

@media screen and (max-width: 63.9375em) {
  body.root-detail section.l_content .l_inner .image-wrap {
    margin: 2em 0;
  }
}

@media print, screen and (min-width: 64em) {
  body.root-detail section.l_content .l_inner .image-wrap img {
    max-width: 320px;
  }
}

@media screen and (max-width: 63.9375em) {
  body.root-detail section.l_content .l_inner .image-wrap img {
    max-width: 600px;
    width: 100%;
  }
}

body.root-detail section.l_content .l_inner .next-spot {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color: #0078D7;
}

body.root-detail section.l_content .l_inner .next-spot p.time-arrow {
  width: 55px;
  height: 55px;
  border-right: 2px solid #0078D7;
  border-bottom: 2px solid #0078D7;
  transform: rotate(45deg);
  margin: auto;
  position: relative;
  z-index: 1;
}

body.root-detail section.l_content .l_inner .next-spot span {
  font-weight: bold;
}

body.root-detail .confirm-root-button {
  text-align: center;
  font-weight: bold;
  margin: 5em auto;
  display: flex;
  justify-content: center;
  width: 340px;
  position: relative;
  transition: 0.2s ease-out;
}

@media screen and (max-width: 39.9375em) {
  body.root-detail .confirm-root-button {
    width: 270px;
  }
}

body.root-detail .confirm-root-button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0078D7;
  z-index: -1;
  transform: skew(-13deg);
  transition: 0.2s ease-out;
}

body.root-detail .confirm-root-button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 14px;
  left: 14px;
  background: none;
  border: 1px solid #0078D7;
  z-index: -2;
  transform: skew(-13deg);
  transition: 0.2s ease-out;
}

body.root-detail .confirm-root-button:hover {
  transition: 0.2s ease-out;
  transform: translate(7px, 7px);
}

body.root-detail .confirm-root-button:hover:before {
  background: #0067b8;
  transition: 0.2s ease-out;
  transform: translate(0, 0) skew(-13deg);
}

body.root-detail .confirm-root-button:hover:after {
  transition: 0.2s ease-out;
  transform: translate(-14px, -14px) skew(-13deg);
}

body.root-detail .confirm-root-button a {
  position: relative;
  margin-bottom: 0;
  color: white;
  text-decoration: none;
  width: 100%;
  padding: 0.6em;
}

body.root-detail .confirm-root-button a span {
  font-size: 1.78571rem;
  margin-left: 0.9em;
}

@media screen and (max-width: 39.9375em) {
  body.root-detail .confirm-root-button a span {
    font-size: 1.42857rem;
  }
}

body.root-detail .confirm-root-button a span:before {
  content: "";
  display: inline-block;
  background: url(../img/lower/icon_confirm-root.svg) no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0.6em;
  left: 0.7em;
  margin: auto;
}

body.spot-detail .page-contents {
  margin-top: 5em;
}

@media screen and (max-width: 63.9375em) {
  body.spot-detail .page-contents {
    margin-top: 2em;
  }
}

body.spot-detail .page-contents .l_content {
  align-items: flex-start;
}

body.spot-detail .page-contents .l_content .spot-info {
  display: flex;
  flex-wrap: wrap;
  border: 4px dotted #95989A;
  padding: 1em;
  margin-bottom: 0;
}

body.spot-detail .page-contents .l_content .spot-info dt {
  width: 20%;
}

body.spot-detail .page-contents .l_content .spot-info dd {
  width: 80%;
}

body.spot-detail .page-contents .l_content .image-wrap .main-photo-block {
  margin-bottom: 2%;
}

@media screen and (max-width: 63.9375em) {
  body.spot-detail .page-contents .l_content .image-wrap .main-photo-block {
    margin-bottom: 1%;
  }
}

body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
}

body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo li {
  width: calc(96% / 3);
  max-height: 120px;
  overflow: hidden;
  margin-bottom: 2%;
}

@media screen and (max-width: 63.9375em) {
  body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo li {
    width: 24%;
    margin-bottom: 1%;
  }
}

@media screen and (max-width: 39.9375em) {
  body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo li {
    width: calc(98% / 3);
  }
}

body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo li img:hover {
  cursor: pointer;
}

@media print, screen and (min-width: 64em) {
  body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo li:not(:nth-of-type(3n+3)) {
    margin-right: 2%;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo li:not(:nth-of-type(4n+4)) {
    margin-right: calc( 4% / 3);
  }
}

@media screen and (min-width: 12.5em) and (max-width: 39.9375em) {
  body.spot-detail .page-contents .l_content .image-wrap ul.thum-photo li:not(:nth-of-type(3n+3)) {
    margin-right: 1%;
  }
}

body.spot-detail .page-contents .gmap-wrap {
  margin: 3em 0;
}

body.spot-detail .page-contents .gmap-wrap .gmap-inner {
  position: relative;
  width: 70%;
  padding-top: 40%;
  margin: 0 auto;
}

@media screen and (max-width: 63.9375em) {
  body.spot-detail .page-contents .gmap-wrap .gmap-inner {
    padding-top: 56.25%;
  }
}

@media screen and (max-width: 39.9375em) {
  body.spot-detail .page-contents .gmap-wrap .gmap-inner {
    padding-top: 75%;
    width: 100%;
  }
}

body.spot-detail .page-contents .gmap-wrap .gmap-inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.time-arrow {
  animation: necessary_time 2s infinite;
}

@keyframes necessary_time {
  0% {
    transform: translateY(-20px) rotate(45deg);
    opacity: 0;
  }
  60% {
    transform: translateY(0) rotate(45deg);
    opacity: 1.0;
  }
  100% {
    transform: translateY(-20px) rotate(45deg);
    opacity: 0;
  }
}

body.language {
  background: linear-gradient(135deg, #00b7ff 0%, #009de0 49%, #2072ba 100%);
}

body.language section.lang-wrap {
  height: 100vh;
  width: 100vw;
  background: url(../img/lower/map-akita.svg) no-repeat;
  background-position: center center;
  background-size: 80% 80%;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 39.9375em) {
  body.language section.lang-wrap {
    width: 100%;
  }
}

body.language section.lang-wrap .lang-inner {
  margin: 0 1em;
}

body.language section.lang-wrap .lang-inner p.en-intro {
  font-size: 2.35714rem;
  line-height: 1.2;
}

body.language section.lang-wrap .lang-inner h1 {
  font-size: 1.42857rem;
  letter-spacing: 0.7rem;
  margin: 1.5em 0;
}

@media screen and (max-width: 39.9375em) {
  body.language section.lang-wrap .lang-inner h1 {
    font-size: 1.14286rem;
    letter-spacing: 0.4rem;
  }
}

body.language section.lang-wrap .lang-inner h2 {
  font-family: "游明朝", "YuMincho", "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  font-size: 5.07143rem;
  line-height: 1.1;
}

@media screen and (max-width: 63.9375em) {
  body.language section.lang-wrap .lang-inner h2 {
    font-size: 3.78571rem;
  }
}

@media screen and (max-width: 39.9375em) {
  body.language section.lang-wrap .lang-inner h2 {
    font-size: 1.64286rem;
  }
}

body.language section.lang-wrap .lang-inner p.lang-choice {
  font-size: 1.42857rem;
  letter-spacing: 0.7rem;
  margin: 2em 0 0;
  font-weight: bold;
}

@media screen and (max-width: 39.9375em) {
  body.language section.lang-wrap .lang-inner p.lang-choice {
    font-size: 1rem;
    letter-spacing: 0.4rem;
    margin: 2em 0 2em;
  }
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link {
  list-style: none;
  font-size: 1.42857rem;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 39.9375em) {
  body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link {
    margin: 0 1.3em;
  }
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li {
  border: 1px solid #fff;
  border-radius: 0;
  width: 30%;
  margin-bottom: 1.3em;
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:hover {
  background: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 63.9375em) {
  body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li {
    width: 40%;
  }
}

@media screen and (max-width: 39.9375em) {
  body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li {
    width: 100%;
  }
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:after {
  content: "";
  border-color: #fff;
}

@media print, screen and (min-width: 64em) {
  body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:first-of-type {
    margin: 0 0 1.3em;
  }
}

@media print, screen and (min-width: 64em) {
  body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:not(:nth-of-type(3)) {
    margin-right: 1.3em;
  }
}

@media screen and (max-width: 63.9375em) {
  body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:nth-of-type(odd) {
    margin-right: 1.3em;
  }
}

@media screen and (max-width: 39.9375em) {
  body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:nth-of-type(odd) {
    margin-right: 0;
  }
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li a {
  color: #fff;
  text-decoration: none;
  padding: 0.4em 0;
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:nth-of-type(1) span:before {
  content: "";
  display: inline-block;
  background: url(../img/lower/icon-japan.svg) no-repeat;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 0.4em;
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:nth-of-type(2) span:before {
  content: "";
  display: inline-block;
  background: url(../img/lower/icon-en.svg) no-repeat;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 0.4em;
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:nth-of-type(3) span:before {
  content: "";
  display: inline-block;
  background: url(../img/lower/icon-korea.svg) no-repeat;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 0.4em;
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:nth-of-type(4) span:before {
  content: "";
  display: inline-block;
  background: url(../img/lower/icon-taiwan.svg) no-repeat;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 0.4em;
}

body.language section.lang-wrap .lang-inner ul.lang-list.arrow-link li:nth-of-type(5) span:before {
  content: "";
  display: inline-block;
  background: url(../img/lower/icon-china.svg) no-repeat;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 0.4em;
}

body.root-list .page-header {
  background: url(../img/lower/recommend_header-bg-01.png) no-repeat;
  background-size: cover;
  height: 145px;
  color: #fff;
  display: flex;
  align-items: center;
}

body.root-list .page-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #0078D7;
}

.spot-detail .page-header {
  background: url(../img/lower/spot_detail-header-bg.png) no-repeat;
  background-size: cover;
  height: 145px;
  color: #fff;
  display: flex;
  align-items: center;
}

.spot-detail .page-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #0078D7;
}

body.spot-detail h2 {
  margin-bottom: 1em;
  border-bottom: 1px solid #0078D7;
  padding-bottom: 0.5em;
}

body.howtouse .page-header {
  background: url(../img/lower/how_to-header-bg.png) no-repeat;
  background-size: cover;
  height: 145px;
  color: #fff;
  display: flex;
  align-items: center;
}

body.howtouse .page-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #0078D7;
}

body.howtouse article {
  margin-bottom: 3em;
}

body.howtouse h2 span {
  background: linear-gradient(transparent 80%, rgba(0, 120, 215, 0.6) 0%);
}

body.howtouse h3:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #0078D7;
  margin-right: 0.5em;
}

@media screen and (max-width: 63.9375em) {
  body.howtouse h3:before {
    vertical-align: text-bottom;
  }
}

@media print, screen and (min-width: 64em) {
  body.howtouse table.price-table {
    width: 80%;
  }
}

body.howtouse table.price-table th, body.howtouse table.price-table td {
  width: calc( 100% / 3);
}

body.howtouse table.price-table td:first-of-type {
  font-weight: bold;
}

body.howtouse .notice {
  margin-top: 0.5em;
}

@media print, screen and (min-width: 64em) {
  body.howtouse .notice {
    width: 80%;
  }
}

body.booking .page-header {
  background: url(../img/lower/how_to-header-bg.png) no-repeat;
  background-size: cover;
  height: 145px;
  color: #fff;
  display: flex;
  align-items: center;
}

body.booking .page-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #0078D7;
}

body.booking .alert.alert_error.notranslate {
  position: relative;
}

body.booking .alert.alert_error.notranslate:after {
  content: "";
  width: calc(100% - 7px);
  height: calc(100% - 7px);
  border: 1px solid #fff;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

body.booking .alert.alert_error.notranslate strong {
  font-size: 0.14286rem;
  color: #fff;
  letter-spacing: 0.3em;
}

body.booking .reserve-wrap {
  margin: 4em 0;
}

body.booking .reserve-wrap select {
  width: auto;
  margin: 0;
}

body.booking .reserve-wrap label {
  display: inline-block;
  margin-right: 0.7em;
}

body.booking .reserve-wrap table tr th {
  border: 1px solid #fff;
  background-color: #0078D7;
}

@media print, screen and (min-width: 64em) {
  body.booking .reserve-wrap table tr th {
    width: 30%;
  }
}

body.booking .reserve-wrap table tr th label {
  color: #fff;
  font-weight: bold;
}

body.booking .reserve-wrap table tr:first-of-type th {
  border-top: 1px solid #0078D7;
}

body.booking .reserve-wrap table tr:last-of-type th {
  border-bottom: 1px solid #0078D7;
}

body.booking .reserve-wrap table tr td {
  padding: 1.5em 1em;
}

@media screen and (max-width: 39.9375em) {
  body.booking .reserve-wrap table tr td {
    text-align: center;
  }
}

body.booking .reserve-wrap table input {
  margin: 0;
}

body.booking .reserve-wrap table .taxi-number-box {
  margin-top: 0.5em;
}

body.booking .reserve-wrap .reserve-btn {
  text-align: center;
  font-weight: bold;
  margin: 5em auto;
  display: flex;
  justify-content: center;
  width: 340px;
  position: relative;
  transition: 0.2s ease-out;
}

@media screen and (max-width: 39.9375em) {
  body.booking .reserve-wrap .reserve-btn {
    width: 270px;
  }
}

body.booking .reserve-wrap .reserve-btn:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0078D7;
  z-index: -1;
  transform: skew(-13deg);
  transition: 0.2s ease-out;
}

body.booking .reserve-wrap .reserve-btn:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 14px;
  left: 14px;
  background: none;
  border: 1px solid #0078D7;
  z-index: -2;
  transform: skew(-13deg);
  transition: 0.2s ease-out;
}

body.booking .reserve-wrap .reserve-btn:hover {
  transition: 0.2s ease-out;
  transform: translate(7px, 7px);
}

body.booking .reserve-wrap .reserve-btn:hover:before {
  background: #0067b8;
  transition: 0.2s ease-out;
  transform: translate(0, 0) skew(-13deg);
}

body.booking .reserve-wrap .reserve-btn:hover:after {
  transition: 0.2s ease-out;
  transform: translate(-14px, -14px) skew(-13deg);
}

body.booking .reserve-wrap .reserve-btn input {
  position: relative;
  margin-bottom: 0;
  text-decoration: none;
  width: 100%;
  padding: 0.6em;
  background: none;
  border: none;
  color: white;
  font-size: 1.78571rem;
}

@media screen and (max-width: 39.9375em) {
  body.booking .reserve-wrap .reserve-btn input {
    font-size: 1.42857rem;
  }
}

body.booking .reserve-wrap .reserve-btn input:hover {
  cursor: pointer;
}
