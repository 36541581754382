footer {
	background-color: #414141;
	color:$white;
	.footer-logo{
		width:300px;
		margin:0 auto;
		a{
			display: block;
			padding:1.5em;
			img{
				height:100%;
			}
		}
	}
	address{
		p{
			margin-bottom: 0;
			span.responsible{
				font-weight: bold;
			}
		}
		a{
			text-decoration: none;
			color:$white;
			
		}
	}
}