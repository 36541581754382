﻿//
// 利用方法
//

body.howtouse{
	.page-header{
		background:url(#{$imgDir}/lower/how_to-header-bg.png)no-repeat;
		@include page-header();
	}
	article{
		margin-bottom: 3em;
	}
	h2{
		span{
			background: linear-gradient(transparent 80%, rgba($primary-color,0.6) 0%);
		}
	}
	h3{
		&:before {
	    content: "";
	    display: inline-block;
	    width: 25px;
	    height: 25px;
	    background: $primary-color;
	    margin-right: 0.5em;
		    @include breakpoint(medium down) {
		    	vertical-align: text-bottom;

		    }
		}
	}
	table.price-table{
		@include breakpoint(large) {
		    	width:80%;
		}
		th,td{
			width:calc( 100% / 3);
		}
		td{
			&:first-of-type{
				font-weight: bold;
			}
		}
	}
	.notice{
		margin-top: 0.5em;
		@include breakpoint(large) {
		    	width:80%;
		}
	}
}