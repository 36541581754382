//
// list
//
.l_list-style{
	list-style: none;
	li{
		margin-bottom: 0.5em;
	}
	li:before{
		content: "";
		display: inline-block;
		width: 12px;
		height: 12px;
		background: $primary-color;
		margin-right: 1em;
	}
	li.root-detail{
		&:before{
			content:"";
			display: none;
		}
	}
}