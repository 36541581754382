﻿//
// recommend
//

.spot-detail .page-header{
	background:url(#{$imgDir}/lower/spot_detail-header-bg.png)no-repeat;
	@include page-header();
}
body.spot-detail{
	h2{
		margin-bottom: 1em;
		border-bottom: 1px solid $primary-color;
		padding-bottom: 0.5em;
	}
}