﻿// recommend
//
.recommend-root-list{
	article{
		margin-bottom: 3em;
	}
	.page-header{
		background:url(#{$imgDir}/lower/recommend-header-bg.png)no-repeat;
		@include page-header();
	}
}
body.jositei-detail .page-header{
	h1{
		font-size: rem-calc(30);
		@include breakpoint(small down) {
			font-size: rem-calc(22);
		}
	}
}
body.recommend{
	p.back-button-wrap{
		text-align: center;
		margin-bottom: 3em;
		a.back-button{
			background-color: $gray;
			color:$white;
			text-decoration: none;
			padding:1em 1.5em;
			border-radius:4px;
			margin-bottom: 0;
			&:hover{
				background-color: darken($gray,10%);
			}
			&:before{
			    content: "";
			    display: inline-block;
			    width: 15px;
			    height: 15px;
			    border-right: 1px solid $white;
			    border-bottom: 1px solid $white;
			    transform: rotate(135deg);
			    vertical-align: bottom;
			    margin-right: 1em;
			}
		}
	}
	.recommend-wrap{
		h2{
			&:before{
				content: "";
				display: inline-block;
				background:url(#{$imgDir}/lower/icon_route.svg)no-repeat;
				width: 50px;
				height: 50px;
				margin-right: 0.5em;
			}
		}
		h2{
			margin-bottom: 1em;
		}
		.four_h{
			margin-top: 2em;
		}
	}
	.page-contents{
		max-width: $global-width;
		margin:auto;
		.recommend{
			// max-width:1040;
			// margin:auto;
		}
		.l_content{
			// margin-bottom: 1em;
			@include breakpoint(medium down) {
				// margin-bottom: 3em;
			}
			@include breakpoint(large) {
				display: flex;
			}

			.image-wrap{
				@include breakpoint(medium down) {
					margin-top: 2em;
				}
			}
			.text-wrap{
				align-self:flex-start;
			}
			h3{
				background: $primary-color;
				color: $white;
				position: relative;
				border-radius: 5px;
				padding: 0.2em 0.5em;
				font-size: rem-calc(25);
				margin-bottom: 0.5em;
				&:after{
					content: "";
				    display: block;
				    position: absolute;
				    width: 0;
				    height: 0;
				    border: 15px solid transparent;
				    border-top: 15px solid $primary-color;
				    left: 40px;
				}
			}
			.root{
				li{
					&:first-of-type{
						display: none;
					}
					&:last-of-type{
						border: 2px solid $primary-color;
						border-radius: 5px;
						position: relative;
						transition: .2s;
						&:after{
							content: "";
							position: absolute;
							right: 15px;
							top: 0;
							bottom: 0;
							margin: auto;
							width: 15px;
							height: 15px;
							border-right: 1px solid $primary-color;
							border-bottom: 1px solid $primary-color;
							-webkit-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}
						&:hover{
							background-color: $primary-color;
							&:after{
								border-right: 1px solid $white;
								border-bottom: 1px solid $white;
							}
						}
					}
				}
			}
		}
	}
}
body.root-detail{
	.page-header{
		background:url(#{$imgDir}/lower/root_detail-header-bg.png)no-repeat;
		@include page-header();
	}
	.page-intro{
		h2{
			span{
				background: linear-gradient(transparent 80%, rgba($primary-color,0.6) 0%);
			}
		}
	}
	section.l_content{
		position: relative;
		&:nth-of-type(even){
			background-color: #F5F5F5;
			&:after{
				content: "";
			    display: block;
			    position: absolute;
			    left: 0;
			    top: 0;
			    right: 0;
			    width: 0;
			    height: 0;
			    margin: auto;
			    border: 40px solid transparent;
			    border-top: 40px solid $white;
			}
			&:before{
				content: "";
			    display: block;
			    position: absolute;
			    left: 0;
			    top: 100%;
			    right: 0;
			    width: 0;
			    height: 0;
			    margin: auto;
			    border: 40px solid transparent;
			    border-top: 40px solid #F5F5F5;
			}
		}
		.l_inner{
			position: relative;
			padding:5em 0;
			@include breakpoint(large) {
				display: flex;
			}
			h2{
				// @include breakpoint(medium down) {
				// 	margin-top: 2.5em;
				// }
				&:before {
			    content: "";
			    display: inline-block;
			    width: 25px;
			    height: 25px;
			    background: $primary-color;
			    margin-right: 0.5em;
				    @include breakpoint(medium down) {
				    	vertical-align: text-bottom;

				    }
				}
			}
			.image-wrap{
				margin:1em 0;
				@include breakpoint(large) {
					align-self: center;
				}
				@include breakpoint(medium down) {
					margin:2em 0;
				}
				img{
					@include breakpoint(large) {
						max-width:320px;
					}
					@include breakpoint(medium down) {
						max-width:600px;
						width:100%;
					}
				}
			}
			.next-spot{
				position: absolute;
				bottom:-30px;
				left:0;
				right:0;
				margin: auto;
				text-align: center;
				color:$primary-color;
				p.time-arrow{
					width:55px;
					height:55px;
					border-right:2px solid $primary-color;
					border-bottom:2px solid $primary-color;
					transform:rotate(45deg);
					margin:auto;
					position: relative;
					z-index: 1;
				}
				span{
					font-weight: bold;
				}
			}
		}
	}
	.confirm-root-button{
		text-align: center;
		font-weight: bold;
		margin: 5em auto;
		display: flex;
		justify-content: center;
		width:340px;
		@include breakpoint(small down) {
			width:270px;
		}
		position: relative;
		transition: 0.2s ease-out;
		&:before{
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $primary-color;
			z-index: -1;
			transform: skew(-13deg);
			transition: 0.2s ease-out;

		}
		&:after{
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 14px;
			left: 14px;
			background:none;
			border:1px solid $primary-color;
			z-index: -2;
			transform: skew(-13deg);
			transition: 0.2s ease-out;
		}
		&:hover{
			transition: 0.2s ease-out;
			transform:translate(7px,7px);
		}
		&:hover:before{
			background:darken($primary-color,6%);
			transition: 0.2s ease-out;
			transform:translate(0,0) skew(-13deg);
		}
		&:hover:after{
			transition:0.2s ease-out;
			transform:translate(-14px,-14px) skew(-13deg);
		}
		a{
			position: relative;
			margin-bottom: 0;
			color: white;
		    text-decoration: none;
		    width:100%;
		    padding: 0.6em;
			span{
				font-size: rem-calc(25);
				margin-left: 0.9em;
				@include breakpoint(small down) {
					font-size: rem-calc(20);
				}
				&:before{
					content: "";
					display: inline-block;
					background:url(#{$imgDir}/lower/icon_confirm-root.svg)no-repeat;
					width:30px;
					height:30px;
					position: absolute;
					top:0.6em;
					left:0.7em;
					margin:auto;
				}
			}
		}
	}
}
body.spot-detail{
	.page-contents{
		margin-top: 5em;
		@include breakpoint(medium down) {
			margin-top: 2em;
		}
		.l_content{
			align-items:flex-start;
			.spot-info{
				display: flex;
				flex-wrap:wrap;
				border: 4px dotted $gray;
				padding: 1em;
				margin-bottom:0;
				dt{
					width:20%;
				}
				dd{
					width:80%;
				}
			}
			.image-wrap{
				.main-photo-block{
					margin-bottom: 2%;
					@include breakpoint(medium down) {
						margin-bottom: 1%;
					}
				}
				ul.thum-photo{
					list-style: none;
					display: flex;
					align-items:center;
					flex-wrap:wrap;
					text-align: left;
					li{
						width: calc(96% / 3 );
						max-height: 120px;
						overflow: hidden;
						margin-bottom:2%;
						@include breakpoint(medium down) {
							width:24%;
							margin-bottom: 1%;
						}
						@include breakpoint(small down) {
							width:calc(98% / 3);
						}
						img{
							&:hover{
								cursor: pointer;
							}
							// width:200px;
						}
						&:not(:nth-of-type(3n+3)){
							@include breakpoint(large) {
								margin-right: 2%;
							}
						}
						&:not(:nth-of-type(4n+4)){
							@include breakpoint(medium only) {
								margin-right: calc( 4% / 3);
							}
						}
						&:not(:nth-of-type(3n+3)){
							@include breakpoint(small only) {
								margin-right: 1%;
							}
						}
					}
				}
			}
		}
		.gmap-wrap{
			margin:3em 0;
			.gmap-inner{
				position: relative;
				width:70%;
				padding-top:40%;
				margin: 0 auto;
				@include breakpoint(medium down) {
					padding-top:56.25%;
				}
				@include breakpoint(small down) {
					padding-top:75%;
					width:100%;
				}
				iframe{
					position: absolute;
					top:0;
					left:0;
					height:100%;
					width:100%;
				}
			}
		}
	}
}


.time-arrow{
	animation:necessary_time 2s infinite;
}
@keyframes necessary_time{
	0%{
		transform:translateY(-20px) rotate(45deg);
		opacity:0;
	}
	60%{
		transform:translateY(0) rotate(45deg);
		opacity:1.0;
	}
	100%{
		transform:translateY(-20px) rotate(45deg);
		opacity:0;
	}

}