﻿//
// language
//



body.language{
		background: linear-gradient(135deg, rgba(0,183,255,1) 0%, rgba(0,157,224,1) 49%, rgba(32,114,186,1) 100%);
	section.lang-wrap{
			height: 100vh;
			width: 100vw;
		@include breakpoint(small down) {
			width:100%;
		}
		background: url(#{$imgDir}/lower/map-akita.svg) no-repeat;
		background-position: center center;
		background-size: 80% 80%;
		color:$white;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		.lang-inner{
			margin:0 1em;
			p.en-intro{
				font-size: rem-calc(33);
				line-height: 1.2;
			}
			h1{
				font-size: rem-calc(20);
				letter-spacing: 0.7rem;
				margin:1.5em 0;
				@include breakpoint(small down) {
					font-size: rem-calc(16);
					letter-spacing: 0.4rem;
				}
			}
			h2{
				font-family:$language-font-family;
				font-size: rem-calc(71);
				line-height: 1.1;
				@include breakpoint(medium down) {
					font-size: rem-calc(53);
				}
				@include breakpoint(small down) {
					font-size: rem-calc(23);
				}
			}
			p.lang-choice{
				font-size: rem-calc(20);
				letter-spacing: 0.7rem;
				margin: 2em 0 0;
				font-weight: bold;
				@include breakpoint(small down) {
					font-size: rem-calc(14);
					letter-spacing: 0.4rem;
					margin: 2em 0 2em;
				}
			}
			ul.lang-list.arrow-link{
				list-style: none;
				font-size: rem-calc(20);
				display: flex;
				flex-wrap: wrap;
				@include breakpoint(small down) {
					margin:0 1.3em;
				}
				li{
					border:1px solid $white;
					border-radius: 0;
					width:30%;
					margin-bottom: 1.3em;
					&:hover{
						    background: rgba(255, 255, 255, 0.2);
					}
					@include breakpoint(medium down) {
						width:40%;
					}
					@include breakpoint(small down) {
						width:100%;
					}
					&:after{
						content:"";
						border-color:$white;

					}
					@include breakpoint(large) {
						&:first-of-type{
						margin:0 0 1.3em;
						}
					}
					@include breakpoint(large) {
						&:not(:nth-of-type(3)){
							margin-right:1.3em;
						}
					}
					@include breakpoint(medium down) {
						&:nth-of-type(odd){
							margin-right:1.3em;
						}
					}
					@include breakpoint(small down) {
						&:nth-of-type(odd){
							margin-right:0;
						}
					}
					a{
						color:$white;
						text-decoration: none;
						padding: 0.4em 0;
					}
					&:nth-of-type(1){
						span{
							&:before{
								content:"";
								display: inline-block;
								background: url(#{$imgDir}/lower/icon-japan.svg)no-repeat;
								width:20px;
								height:20px;
								vertical-align: text-bottom;
								margin-right: 0.4em;
							}
						}
					}
					&:nth-of-type(2){
						span{
							&:before{
								content:"";
								display: inline-block;
								background: url(#{$imgDir}/lower/icon-en.svg)no-repeat;
								width:20px;
								height:20px;
								vertical-align: text-bottom;
								margin-right: 0.4em;
							}
						}
					}
					&:nth-of-type(3){
						span{
							&:before{
								content:"";
								display: inline-block;
								background: url(#{$imgDir}/lower/icon-korea.svg)no-repeat;
								width:20px;
								height:20px;
								vertical-align: text-bottom;
								margin-right: 0.4em;
							}
						}
					}
					&:nth-of-type(4){
						span{
							&:before{
								content:"";
								display: inline-block;
								background: url(#{$imgDir}/lower/icon-taiwan.svg)no-repeat;
								width:20px;
								height:20px;
								vertical-align: text-bottom;
								margin-right: 0.4em;
							}
						}
					}
					&:nth-of-type(5){
						span{
							&:before{
								content:"";
								display: inline-block;
								background: url(#{$imgDir}/lower/icon-china.svg)no-repeat;
								width:20px;
								height:20px;
								vertical-align: text-bottom;
								margin-right: 0.4em;
							}
						}
					}
				}
			}
		}
	}
}