//
// Button - Foundation sites
//

.button {

	&:visited {
		color: $button-color;
	}

	@if $anchor-text-decoration == underline {
		text-decoration: none;
	}
}