
	@include breakpoint(large) {
		.sp-only{
			display: none;
		}
	}
	@include breakpoint(medium down) {
		.pc-only{
			display: none;
		}
	}


main{
	position: relative;
	.footer-link-top{
		position: fixed;
		bottom:1em;
		right:2em;
		a.button{
			width:60px;
			height:60px;
			position: relative;
			border-radius: 50%;
			background-color: $primary-color;
			border:1px solid $primary-color;
			&:after{
				content: "";
				display: block;
				position: absolute;
				top:10px;
				left:0;
				right:0;
				bottom:0;
				margin:auto;
				width:20px;
				height:20px;
				border-bottom: 1px solid $white;
				border-right: 1px solid $white;
				transform: rotate(-135deg);
			}
			&:hover{
				background:$white;
				&:after{
					border-color:$primary-color;
				}
			}
		}
	}
	// 下層ページ共通
	.page-contents{
		.page-intro{
			margin:3em 1em;
			h2{
				margin-bottom: 1em;
			}
			.intro-text{
				font-weight: bold;
				font-size: rem-calc(20);
				line-height: 1.8;
				@include breakpoint(medium down) {
					font-size:rem-calc(18)
				}
			}
		}
	}
	//リンクボタン
	ul.arrow-link{
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 2em 0 0 0;
		@include breakpoint(medium down) {
			display: block;
		}
		li{
			border:2px solid $border-primary;
			border-radius: 5px;
			position: relative;
			transition:0.2s;
			&:after{
				content: "";
				position: absolute;
				right:15px;
				top:0;
				bottom: 0;
				margin:auto;
				width:15px;
				height:15px;
				border-right: 1px solid $border-primary;
				border-bottom: 1px solid $border-primary;
				transform: rotate(-45deg);

			}
			&:hover{
				transition:0.2s;
				background-color: $border-primary;
				a{
					color:$white;
				}
				&:after{
					border-right: 1px solid $white;
					border-bottom: 1px solid $white;
				}
			}
			a{
				position: relative;
				text-decoration: none;
				font-size: rem-calc(18);
				display: block;
				text-align: center;
				padding: 0.2em 2em;
				color:$body-font-color;

			}
			&:first-of-type{
				// 自由選択ルートが復活したら復活
				// margin-right: 3em;
				@include breakpoint(medium down) {
					margin:0 0 1em 0;
				}
			}
		}
	}
	.arrow-link.root{
		justify-content:flex-start;
		margin-top: 0;
		margin-bottom: 3em;
		li:first-of-type{
			margin:0;
		}
		@include breakpoint(medium down) {
			width:80%;
			margin:auto;
		}
	}
	aside.banner-list{
		p{
			a{
				transition:0.2s;
				&:hover{
					transition:0.2s;
					opacity:0.6;
				}
			}
		}
	}
	.corse-text{
		line-height: 2.5;
		ul{
			li{
				line-height: 2.5;
			}
		}
	}
}
.site-logo{
	transition:0.2s;
	&:hover{
		transition:0.2s;
		opacity:0.6;
	}
}

