//
// Page header (H1)
//

.page-header {
	.page-title-inner{
		h1{
			margin-bottom: 0;
		}
	}
}